export default [
  {
    path: 'promos',
    name: 'promos',
    component: () =>
      import(/* webpackChunkName: "promos" */ '@/views/Promos/List'),
  },

  {
    path: 'promos/new',
    name: 'new.promo',
    redirect: { name: 'new.promos.details' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "new-promo" */ '@/views/Promos/Informations'),
    children: [
      {
        path: ':id?/details',
        name: 'new.promos.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-promo-page" */ '@/views/Promos/Informations/Details'
          ),
      },
    ],
  },

  {
    path: 'promos/:id?',
    name: 'promo.info',
    redirect: { name: 'promos.details' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "promo" */ '@/views/Promos/Informations'),
    children: [
      {
        path: 'promos',
        name: 'promos.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "promo-page" */ '@/views/Promos/Informations/Details'
          ),
      },
    ],
  },
]

import Promo from '@/models/Promo'
import PromoType from '@/models/PromoType'

/**
 *
 * @param {string} page
 * @param {string|number} page
 * @param {string|number} limit
 */
export const getPromos = async ({ commit }, { search, page, limit = 50 }) => {
  commit('setPromoLoading', true)

  const query = Promo.page(page)

  if (search) {
    query.where('search', search)
  }

  let list = await query.params({ limit }).get()

  commit('setPromoLoading', false)
  commit('setPromoList', list)
}

/**
 *
 * @param {string|number} id
 */
export const getPromo = async ({ commit }, id) => {
  commit('setPromoLoading', true)

  let promo = await Promo.$find(id)

  commit('setPromoLoading', false)
  commit('setSelectedPromo', promo)
}

/**
 * Save Promo details
 *
 * form parameters
 * @param {object} params
 */
export const savePromo = ({ commit }, params) => {
  return new Promo(params).save()
}

/**
 * Delete Promo
 *
 * @param {integer} id
 */
export const deletePromo = async ({ commit }, id) => {
  commit('deletingPromo', true)

  const promo = new Promo({ id })

  await promo.delete()

  commit('deletingPromo', false)
  commit('removePromo', promo)
}

export function reorderPromos({ commit }, promos) {
  return this.$api.post(`promos/reorder`, { promos })
}

export const fetchPromoTypes = async ({ commit }, params) => {
  let { page } = params
  let data = await PromoType.page(page)
    .params({ limit: 100 })
    .get()

  commit('setPromoTypes', data.data)
}

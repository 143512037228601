export const setWorkoutExtraCategories = (state, { data, meta }) => {
  state.workoutExtraCategories.list = data
  state.workoutExtraCategories.meta = meta
}

export const setWorkoutExtraSubCategories = (state, { data, meta }) => {
  state.workoutExtraSubCategories.list = data
  state.workoutExtraSubCategories.meta = meta
}

export const setSelectedWorkoutExtraCategory = (
  state,
  workoutExtraCategory
) => {
  if (state.selectedWorkoutExtraCategory) {
    state.selectedWorkoutExtraCategory.selected = false
  }
  workoutExtraCategory.selected = true
  state.selectedWorkoutExtraCategory = workoutExtraCategory
}

export const setSelectedWorkoutExtraSubCategory = (
  state,
  workoutExtraSubCategory
) => {
  state.selectedWorkoutExtraSubCategory = workoutExtraSubCategory
}

function fillObject(bucketObject, tapObject, fields) {
  fields.forEach((prop) => (bucketObject[prop] = tapObject[prop]))
}

export const updateOrInsertWorkExtraCategoryItem = (
  state,
  newWorkoutExtraCategory
) => {
  let targetWorkoutExtraCategory = state.workoutExtraCategories.list.find(
    (item) => item.id === newWorkoutExtraCategory.id
  )
  if (targetWorkoutExtraCategory) {
    fillObject(targetWorkoutExtraCategory, newWorkoutExtraCategory, [
      'name',
      'description',
      'order',
      'icon',
      'deleted_at',
    ])
  } else {
    state.workoutExtraCategories.list.push(newWorkoutExtraCategory)
  }
}

export const removeWorkExtraCategoryItem = (state, workoutExtraCategory) => {
  if (state.selectedWorkoutExtraCategory === workoutExtraCategory) {
    state.selectedWorkoutExtraCategory = {}
    state.workoutExtraSubCategories.list = []
  }
  state.workoutExtraCategories.list = state.workoutExtraCategories.list.filter(
    (item) => item.id !== workoutExtraCategory.id
  )
}

export const updateOrInsertWorkExtraSubCategoryItem = (
  state,
  newWorkoutExtraSubCategory
) => {
  let targetWorkoutExtraSubCategory = state.workoutExtraSubCategories.list.find(
    (item) => item.id === newWorkoutExtraSubCategory.id
  )

  if (targetWorkoutExtraSubCategory) {
    fillObject(targetWorkoutExtraSubCategory, newWorkoutExtraSubCategory, [
      'name',
      'description',
      'order',
      'deleted_at',
    ])
  } else {
    state.workoutExtraSubCategories.list.push(newWorkoutExtraSubCategory)
  }
}

export const removeWorkExtraSubCategoryItem = (
  state,
  workoutExtraSubCategory
) =>
  (state.workoutExtraSubCategories.list = state.workoutExtraSubCategories.list.filter(
    (item) => item.id !== workoutExtraSubCategory.id
  ))

import TrainingProgram from '@/models/TrainingProgram'

export const getTrainingPrograms = async ({ commit }, params) => {
  let { page, workoutTypeId } = params

  return TrainingProgram.where('workout_type_id', workoutTypeId)
    .page(page)
    .params({ limit: 100 })
    .get()
}

export const saveTrainingProgram = async ({ commit }, params) => {
  if (!params.id) return new TrainingProgram(params).save()

  return new TrainingProgram(params).save()
}

export const deleteTrainingProgram = ({ commit }, TrainingProgram) => {
  return TrainingProgram.delete()
}

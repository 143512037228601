import WorkoutExtraCategory from '@/models/WorkoutExtraCategory'
import WorkoutExtraSubCategory from '@/models/WorkoutExtraSubCategory'

export const getWorkoutExtraCategories = async ({ commit }, params) => {
  let { page } = params

  return WorkoutExtraCategory.page(page)
    .where('trashed', 'with')
    .params({ limit: 100 })
    .get()
}

export const getWorkoutExtraSubCategories = async ({ commit }, params) => {
  let { page, workoutExtraCategory } = params

  return workoutExtraCategory
    .subCategories()
    .params({ limit: 100 })
    .page(page)
    .get()
}

export const saveWorkoutExtraCategory = ({ commit }, params) => {
  return new WorkoutExtraCategory(params).save()
}

export const enableWorkoutExtraCategory = function(
  { commit },
  workoutExtraCategory
) {
  return this.$api.patch(
    `/settings/workout-extra-categories/${workoutExtraCategory.id}/enable`
  )
}

export const enableWorkoutExtraSubCategory = function(
  { commit },
  workoutExtraSubCategory
) {
  return this.$api.patch(
    `/settings/workout-extra-sub-categories/${workoutExtraSubCategory.id}/enable`
  )
}

export const saveWorkoutExtraSubCategory = (
  { commit },
  { workoutExtraCategory, params }
) => {
  const workoutExtraSubCategory = new WorkoutExtraSubCategory(params).for(
    workoutExtraCategory
  )

  return workoutExtraSubCategory.save()
}

export const deleteWorkoutExtraCategory = (
  { commit },
  workoutExtraCategory
) => {
  return workoutExtraCategory.delete()
}

export const disableWorkoutExtraCategory = function(
  { commit },
  workoutExtraCategory
) {
  return this.$api.delete(
    `/settings/workout-extra-categories/${workoutExtraCategory.id}/disable`
  )
}

export const disableWorkoutExtraSubCategory = function(
  { commit },
  workoutExtraSubCategory
) {
  return this.$api.delete(
    `/settings/workout-extra-sub-categories/${workoutExtraSubCategory.id}/disable`
  )
}

export const deleteWorkoutExtraSubCategory = (
  { commit },
  workoutExtraSubCategory
) => {
  return workoutExtraSubCategory.delete()
}

export function reorderWorkoutExtraCategories({ commit }, categories) {
  return this.$api.post('/settings/workout-extra-categories/reorder', {
    categories,
  })
}

export function reorderWorkoutExtraSubCategories(
  { commit },
  { workoutExtraCategory, categories }
) {
  return this.$api.post(
    `/settings/workout-extra-categories/${workoutExtraCategory.id}/sub-categories/reorder`,
    {
      categories,
    }
  )
}

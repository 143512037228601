export default [
  {
    path: 'user-scheduled-deletion',
    name: 'user-scheduled-deletion',
    component: () =>
      import(
        /* webpackChunkName: "user-scheduled-deletion-page" */ '@/views/UserScheduledDeletion'
      ),
  },
]

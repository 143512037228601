export default [
  {
    path: 'meal-guides',
    name: 'meal-guides',
    redirect: { name: 'active.meals' },
    component: () => import(/* webpackChunkName: "Recipe" */ '@/views/Meals'),

    children: [
      {
        path: 'active',
        name: 'active.meals',
        meta: { type: 1 },
        component: () =>
          import(
            /* webpackChunkName: "active-meals" */ '@/views/Meals/Lists/Active'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.meals',
        meta: { type: 1 },
        component: () =>
          import(
            /* webpackChunkName: "draft-meals" */ '@/views/Meals/Lists/Draft'
          ),
      },
      {
        path: 'deleted',
        name: 'deleted.meals',
        component: () =>
          import(
            /* webpackChunkName: "draft-meals" */ '@/views/Meals/Lists/Deleted'
          ),
      },
    ],
  },
  {
    path: 'meal-guides/:id?/meals-form',
    name: 'form.meals',
    meta: { type: 2 },
    redirect: { name: 'form.meals.details' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "form-meals" */ '@/views/Meals/Details'),
    children: [
      {
        path: 'details',
        name: 'form.meals.details',
        meta: { stage: 1 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-meals-details" */ '@/views/Meals/Details/Details'
          ),
      },
      {
        path: 'meal-guide',
        name: 'form.meals.guide',
        meta: { meal_time: 1, stage: 2 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-meals-morning" */ '@/views/Meals/Details/MealGuide'
          ),
      },
      {
        path: 'preview',
        name: 'form.meals.preview',
        meta: { stage: 3 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-meals-preview" */ '@/views/Meals/Details/Preview'
          ),
      },
    ],
  },
]

import Vue from 'vue'
import Exercise from '@/models/Exercise'
import { each, find, findIndex, isBoolean } from 'lodash'

export default {
  namespaced: true,
  state: {
    completed: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    drafts: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    filter: {
      search: null,
      completed: null,
      trainer: [],
      inTutorialLibrary: null,
      hasCode: null,
    },
    selectedExercise: {
      id: null,
      title: null,
      tempo: null,
      image: null,
      swaps: null,
      venue: null,
      trainer: {},
      equipments: [],
      tariner_id: null,
      created_at: null,
      description: null,
      completed_at: null,
      instructions: null,
      alternatives: {
        swaps: [],
        regressions: [],
        progressions: [],
        no_equipments: [],
      },
    },
  },
  mutations: {
    setExerciseList(state, exercises) {
      const _exercise = state.filter.completed ? state.completed : state.drafts

      each(exercises, (exercise) => {
        const exist = find(_exercise.list, { id: exercise.id })
        if (!exist) {
          _exercise.list.push(new Exercise(exercise))
        }
      })
    },

    clearList(state) {
      let _exercises = state.filter.completed ? state.completed : state.drafts

      _exercises.list = []
    },

    clearSelectedExerciseEquipments(state) {
      state.selectedExercise.equipments = []
    },

    setExerciseMeta(state, meta) {
      let _exercises = state.filter.completed ? state.completed : state.drafts

      _exercises.listMeta = meta
    },

    setSelectedExercise(state, exercise) {
      if (exercise instanceof Exercise) {
        state.selectedExercise = exercise
      } else {
        state.selectedExercise = new Exercise(exercise)
      }
    },

    setFilter(state, filter) {
      state.filter = { ...state.filter, ...filter }
    },

    setSwapExercise(state, { exercise, type }) {
      let stateType = 'no_equipments'
      let alternativeState = state.selectedExercise.alternatives

      if (type === 1) {
        stateType = 'regressions'
      } else if (type === 2) {
        stateType = 'progressions'
      } else if (type === 3) {
        stateType = 'swaps'
      } else {
        stateType = 'no_equipments'
      }

      alternativeState[stateType] = [...alternativeState[stateType], exercise]
    },

    removeSwapExercise(state, { exercise, type }) {
      let stateType = 'no_equipments'
      let alternativeState = state.selectedExercise.alternatives

      if (type === 1) {
        stateType = 'regressions'
      } else if (type === 2) {
        stateType = 'progressions'
      } else if (type === 3) {
        stateType = 'swaps'
      } else {
        stateType = 'no_equipments'
      }

      let index = findIndex(alternativeState[stateType], (state) => {
        return state.id === exercise.id
      })

      Vue.delete(alternativeState[stateType], index)
    },
    resetFilter(state) {
      state.filter.trainer = []
      state.filter.inTutorialLibrary = null
      state.filter.hasCode = null
    },
  },

  getters: {
    getSelectedExerciseInstructions(state) {
      return state.selectedExercise.instructions || []
    },

    getSelectedExerciseEquipments(state) {
      return state.selectedExercise.equipments || []
    },

    getSelectedExerciseRegressions(state) {
      return state.selectedExercise.alternatives
        ? state.selectedExercise.alternatives.regressions
        : []
    },

    getSelectedExerciseProgressions(state) {
      return state.selectedExercise.alternatives.progressions
    },

    getSelectedExerciseSwaps(state) {
      return state.selectedExercise.alternatives.swaps
    },

    getSelectedExerciseNoEquipments(state) {
      return state.selectedExercise.alternatives.no_equipments
    },
  },
  actions: {
    async getExercises({ commit }, params) {
      let {
        page,
        search,
        completed,
        trainer,
        inTutorialLibrary,
        hasCode,
      } = params

      const query = Exercise.page(page)
        .include(['image', 'video', 'regressions', 'progressions', 'swaps'])
        .orderBy('title')

      if (params.search) {
        query.where('search', search)
      }

      if (isBoolean(completed)) {
        query.where('completed', completed)
      }

      if (trainer.length !== 0) {
        query.where('trainer', trainer.join(','))
      }

      if (isBoolean(inTutorialLibrary)) {
        query.where('in_tutorial_library', inTutorialLibrary)
      }

      if (isBoolean(hasCode)) {
        query.where('has_code', hasCode)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setExerciseList', res.data)
      commit('setExerciseMeta', res.meta)
    },

    async getExercise({ commit }, id) {
      let _exercise = await Exercise.include([
        'image',
        'video',
        'swaps',
        'trainer',
        'equipments',
        'regressions',
        'progressions',
        'no_equipments',
      ]).find(id)

      commit('setSelectedExercise', _exercise.data)

      return _exercise
    },

    async saveExercise({ commit }, params) {
      let _exercise = await new Exercise(params).save()
      commit('setSelectedExercise', _exercise)
      return _exercise
    },

    async updateExercise({ commit }, params) {
      let _exercise = await new Exercise(params).save()
      commit('setSelectedExercise', _exercise)
      return _exercise
    },
  },
}

import utc from 'dayjs/plugin/utc'
import Model from './Model'
import dayjs from 'dayjs'

import Workout from './Workout'
import Checkin from './Checkin'
import Video from './Video'
import ProgramVariant from './ProgramVariant'

dayjs.extend(utc)

export default class Challenge extends Model {
  resource() {
    return 'programs'
  }

  workouts() {
    return this.hasMany(Workout)
  }

  videos() {
    return this.hasMany(Video)
  }

  checkins() {
    return this.hasMany(Checkin)
  }

  variants() {
    return this.hasMany(ProgramVariant)
  }

  get startDate() {
    return dayjs.utc(this.starts_at).format('MMM DD, YYYY')
  }

  get endDate() {
    return dayjs.utc(this.ends_at).format('MMM DD, YYYY')
  }

  get isCompleted() {
    return this.completed_at ?? false
  }
}

export default {
  venueTrainingPrograms: {
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  trainingTrainingPrograms: {
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  selectedTrainingProgram: {
    id: null,
    name: null,
    description: null,
    icon_public_id: null,
    workout_type_id: null,
  },
}

export default [
  {
    path: 'admin',
    name: 'admin',
    redirect: { name: 'admin.profile' },
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin'),
    children: [
      {
        path: 'profile',
        name: 'admin.profile',
        meta: {
          pageType: 'Profile',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-profile" */ '@/views/Admin/Profile'
          ),
      },
      {
        path: 'settings',
        name: 'admin.settings',
        meta: {
          pageType: 'Settings',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-settings" */ '@/views/Admin/Settings'
          ),
      },
    ],
  },
]

import Model from './Model'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'

import { find, isNull } from 'lodash'

dayjs.extend(utc)

// It should be on lower case
// To make use of the vue 'title' filter.
const days = [
  { id: 0, name: 'sunday' },
  { id: 1, name: 'monday' },
  { id: 2, name: 'tuesday' },
  { id: 3, name: 'wednesday' },
  { id: 4, name: 'thursday' },
  { id: 5, name: 'friday' },
  { id: 6, name: 'saturday' },
]
export default class Checkin extends Model {
  resource() {
    return 'checkins'
  }

  get dayFrom() {
    return !isNull(this.day_from)
      ? find(days, { id: this.day_from }).name
      : null
  }

  get dayTo() {
    return !isNull(this.day_to) ? find(days, { id: this.day_to }).name : null
  }
}

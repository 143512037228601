import Mindset from '@/models/Mindset'
import { isEmpty } from 'lodash'

// Get mindset list
export const getMindsets = ({ commit }, params) => {
  let { search, page, week, programs } = params

  const query = Mindset.include(['program']).page(page)

  if (!isEmpty(search)) {
    query.where('search', search)
  }

  if (!isEmpty(week)) {
    query.where('week', week)
  }

  if (!isEmpty(programs)) {
    query.whereIn('program_id', programs)
  }

  return query.params({ limit: 30 }).get()
}

// Get mmindset details
export const getMindset = ({ commit }, mindsetId) => {
  return Mindset.include(['program']).find(mindsetId)
}

// Save Mindset
export const saveMindset = ({ commiy }, mindset) => {
  return new Mindset(mindset).save()
}

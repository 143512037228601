import Membership from '@/models/Membership'
import { find, extend, findIndex } from 'lodash'

export const setMembershipList = (state, memberships) => {
  state.list = memberships
}

export const clearList = (state) => {
  let _membership = state.filter.completed ? state.completed : state.drafts

  _membership.list = []
}

export const setMembershipListMeta = (state, meta) => {
  state.listMeta = meta
}

export const setSelectedMembership = (state, membership) => {
  if (membership instanceof Membership) {
    state.selectedMembership = extend(state.selectedMembership, membership)
  } else {
    state.selectedMembership = new Membership(
      extend(state.selectedMembership, membership)
    )
  }
}

/**
 * Membership List Management
 */

export const updateMembershipOnCompletedList = (state, membership) => {
  const _membership = find(state.completed.list, { id: membership.id })

  if (_membership) {
    return Object.assign(_membership, membership)
  }

  const _draftMembershipIndex = findIndex(state.drafts.list, {
    id: membership.id,
  })

  state.completed.list.push(membership)
  state.drafts.list.splice(_draftMembershipIndex, 1)
}

export const updateMembershipOnDraftList = (state, membership) => {
  const _membership = find(state.drafts.list, { id: membership.id })

  if (_membership) {
    Object.assign(_membership, membership)
  } else {
    state.drafts.list.push(membership)
  }
}

export const clearSelectedMembership = (state) => {
  state.selectedMembership = {
    checkins: [],
    variants: [],
    workouts: [],
    deload_workouts: [],
    completed_at: false,
    has_workout_days: false,
    training_memberships: [],
  }
}

export const removeMembershipFormList = (state, id) => {
  return true
}

export const storeVariant = (state, variant) =>
  state.selectedMembership.variants.push(variant)

export const updateVariant = (state, updatedVariant) => {
  const variants = state.selectedMembership.variants
  variants.splice(
    variants.findIndex((item) => item.id === updatedVariant.id),
    1,
    updatedVariant
  )
}

export const removeVariant = (state, deletedVariant) => {
  const variants = state.selectedMembership.variants
  variants.splice(
    variants.findIndex((item) => item.id === deletedVariant.id),
    1
  )
}

export const setLoading = (state, status) => (state.isLoading = status)

import Program from '@/models/Program'

export const getPrograms = async ({ commit }, params) => {
  let { page, search, completed, archived = 0, appends } = params

  const query = Program.page(page)
    .orderBy('name')
    .where('completed', completed)
    .where('archived', archived)
    .where('type', 1)

  if (search) {
    query.where('search', search)
  }

  if (appends) {
    query.append(appends)
  }

  const { data, meta } = await query.params({ limit: 20 }).get()

  commit('setProgramList', data)
  commit('setProgramListMeta', meta)
}

export const getProgram = async ({ commit }, id) => {
  let _program = await Program.include(['workouts', 'trainers', 'videos']).find(
    id
  )

  commit('setSelectedProgram', _program.data)

  return _program
}

export const saveProgram = async ({ commit }, params) => {
  let _program = await new Program(params).save()
  return _program
}

export const updateProgram = async ({ commit }, params) => {
  let program = await new Program(params).save()
  return program
}

export const deleteProgram = ({ commit }, program) => {
  return new Program(program).delete()
}

export const archiveProgram = async ({ commit }, params) => {
  const program = new Program({ id: params.id })
  await Program.custom(
    program,
    params.setArchive ? 'archive' : 'unarchive'
  ).get()
}

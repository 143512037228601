export default [
  {
    path: 'recipe',
    name: 'recipe',
    redirect: { name: 'active.recipe' },
    component: () => import(/* webpackChunkName: "Recipe" */ '@/views/Recipe'),

    children: [
      {
        path: 'active',
        name: 'active.recipe',
        meta: { type: 1, status: 'active' },
        component: () =>
          import(
            /* webpackChunkName: "active-recipe" */ '@/views/Recipe/Lists/ActiveFoods'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.recipe',
        meta: { type: 1, status: 'draft' },
        component: () =>
          import(
            /* webpackChunkName: "draft-recipe" */ '@/views/Recipe/Lists/DraftFoods'
          ),
      },
      {
        path: 'deleted',
        name: 'deleted.recipe',
        meta: { type: 1, status: 'deleted' },
        component: () =>
          import(
            /* webpackChunkName: "deleted-recipe" */ '@/views/Recipe/Lists/DeletedFoods'
          ),
      },
      {
        path: 'import',
        name: 'import.recipe',
        meta: { type: 1 },
        component: () =>
          import(
            /* webpackChunkName: "import-recipe" */ '@/views/Recipe/Lists/ImportRecipe'
          ),
      },
    ],
  },
  {
    path: 'recipe/:id?/recipe-form',
    name: 'form.recipe',
    meta: { type: 2 },
    redirect: { name: 'form.recipe.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "form-recipe" */ '@/views/Recipe/RecipeDetails'
      ),
    children: [
      {
        path: 'details',
        name: 'form.recipe.details',
        meta: { type: 2, stage: 1 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-recipe-details" */ '@/views/Recipe/RecipeDetails/Details'
          ),
      },
      {
        path: 'ingredients',
        name: 'form.recipe.ingredients',
        meta: { type: 2, stage: 2 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-recipe-ingredients" */ '@/views/Recipe/RecipeDetails/Ingredients'
          ),
      },
      {
        path: 'flavour-boosters',
        name: 'form.recipe.flavour-boosters',
        meta: { type: 2, stage: 3 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-recipe-flavour-boosters" */ '@/views/Recipe/RecipeDetails/FlavourBoosters'
          ),
      },
      {
        path: 'directions',
        name: 'form.recipe.directions',
        meta: { type: 2, stage: 4 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-recipe-directions" */ '@/views/Recipe/RecipeDetails/Directions'
          ),
      },
      {
        path: 'preview',
        name: 'form.recipe.preview',
        meta: { type: 2, stage: 5 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-recipe-preview" */ '@/views/Recipe/RecipeDetails/Preview'
          ),
      },
    ],
  },
]

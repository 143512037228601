export default [
  {
    path: 'workouts',
    name: 'workouts',
    redirect: { name: 'active.workouts' },
    component: () =>
      import(/* webpackChunkName: "Workouts" */ '@/views/Workouts'),

    children: [
      {
        path: 'active',
        name: 'active.workouts',
        meta: { active: true, hasUserFilter: true },
        component: () =>
          import(
            /* webpackChunkName: "active-workouts" */ '@/views/Workouts/Lists/ActiveWorkouts'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.workouts',
        meta: { active: false, hasUserFilter: true },
        component: () =>
          import(
            /* webpackChunkName: "draft-workouts" */ '@/views/Workouts/Lists/DraftWorkouts'
          ),
      },
      {
        path: 'import',
        name: 'import.workouts',
        meta: { active: false },
        component: () =>
          import(
            /* webpackChunkName: "import-workouts" */ '@/views/Workouts/Lists/ImportWorkouts'
          ),
      },
    ],
  },
  {
    path: 'workouts/new',
    name: 'new.workout',
    redirect: { name: 'new.workout.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "new-workout" */ '@/views/Workouts/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.workout.details',
        meta: {
          stage: 1,
          new: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-workout-details-form" */ '@/views/Workouts/Informations/WorkoutDetails'
          ),
      },
      {
        path: ':id?/exercises',
        name: 'new.workout.exercises',
        meta: {
          stage: 3,
          new: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-workout-details-form" */ '@/views/Workouts/Informations/WorkoutCircuits'
          ),
      },
    ],
  },
  {
    path: 'workouts/:id',
    name: 'workout',
    props: true,
    redirect: { name: 'workout.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-workout" */ '@/views/Workouts/Informations'
      ),
    children: [
      {
        path: 'details',
        name: 'workout.details',
        meta: {
          stage: 1,
          new: false,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-workout-details-form" */ '@/views/Workouts/Informations/WorkoutDetails'
          ),
      },
      {
        path: 'exercises',
        name: 'workout.exercises',
        meta: {
          stage: 2,
          new: false,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-workout-details-form" */ '@/views/Workouts/Informations/WorkoutCircuits'
          ),
      },
    ],
  },
]

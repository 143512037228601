import FoodCategory from '@/models/FoodCategory'
import { map, isNumber } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    filter: {
      search: null,
      completed: true,
    },
    selectedData: {},
  },

  mutations: {
    setDataList(state, results) {
      state.list = map(results, (row) => {
        if (row instanceof FoodCategory) {
          return row
        } else {
          return new FoodCategory(row)
        }
      })
    },

    clearList(state) {
      state.list = []
    },

    setSelectedData(state, data) {
      if (data instanceof FoodCategory) {
        state.selectedData = data
      } else {
        state.selectedData = new FoodCategory(data)
      }
    },

    setFilter(state, filter) {
      state.filter = filter
    },
  },

  actions: {
    async getDataList({ commit }, params) {
      const { search } = params

      const query = FoodCategory

      // eslint-disable-next-line eqeqeq
      if (search != undefined) {
        if (isNumber(search)) {
          query.where('id', search)
        } else {
          query.where('search', search)
        }
      }

      const { data } = await query.get()

      commit('setDataList', data)
    },

    async getSingleData({ commit }, id) {
      let content = await FoodCategory.find(id)

      commit('setSelectedData', content.data)

      return content
    },

    async saveData({ commit }, params) {
      let { data } = await new FoodCategory(params).save()

      commit('setSelectedData', data)

      return data
    },
  },
}

import Model from './Model'
import Meal from './Meal'

export default class Guide extends Model {
  resource() {
    return 'meals/guides'
  }

  meals() {
    return this.hasMany(Meal)
  }
}

export async function getWhitelistedUsers({ commit }) {
  let { data } = await this.$api.get('user-whitelists')

  commit('setWhitelistedUsers', data.data)
}

export async function addWhitelistedUsers({ commit }, users) {
  return this.$api.get('user-whitelists')
}

export async function addNewWhitelistedUser({ commit }, email) {
  return this.$api.post('user-whitelists', { email })
}

export async function removeWhitelistedUser({ commit }, id) {
  return this.$api.delete(`user-whitelists/${id}`)
}

export const getSelectedChallenge = (state) => {
  return state.selectedChallenge
}

export const getSelectedChallengeCheckins = (state) => {
  return state.selectedChallenge.checkins
}

export const isSelectedChallengeComplete = (state) => {
  return !!state.selectedChallenge.completed_at
}

export const getSelectedChallengeVariants = (state) => {
  return state.selectedChallenge.variants
}

export const getSelectedChallengeVideo = (state) => {
  return state.selectedChallenge.videos
}

export const getSelectedChallengeWorkout = (state) => {
  return state.selectedChallenge.workouts
}

export const getSelectedChallengeDeloadWorkouts = (state) => {
  return state.selectedChallenge.deload_workouts
}

export const getSelectedChallengeTrainingPrograms = (state) => {
  return state.selectedChallenge.training_programs
}

export default [
  {
    path: 'foods',
    name: 'foods',
    redirect: { name: 'active.foods' },
    component: () => import(/* webpackChunkName: "Foods" */ '@/views/Foods'),

    children: [
      {
        path: 'active',
        name: 'active.foods',
        meta: { type: 2, status: 'active' },
        component: () =>
          import(
            /* webpackChunkName: "active-foods" */ '@/views/Foods/Lists/ActiveFoods'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.foods',
        meta: { type: 2, status: 'draft' },
        component: () =>
          import(
            /* webpackChunkName: "drafts-foods" */ '@/views/Foods/Lists/DraftFoods'
          ),
      },
      {
        path: 'deleted',
        name: 'deleted.foods',
        meta: { type: 2, status: 'deleted' },
        component: () =>
          import(
            /* webpackChunkName: "deleted-foods" */ '@/views/Foods/Lists/DeletedFoods'
          ),
      },
      {
        path: 'import',
        name: 'import.foods',
        component: () =>
          import(
            /* webpackChunkName: "import-foods" */ '@/views/Foods/Lists/ImportFoodJournal'
          ),
      },
    ],
  },
  {
    path: 'foods/:id?/food-form',
    name: 'form.food',
    meta: { type: 2 },
    props: true,
    component: () =>
      import(/* webpackChunkName: "form-food" */ '@/views/Foods/FoodForm'),
  },
]

import Image from '@/models/Image'
import Food from '@/models/Food'
import FoodCategory from '@/models/FoodCategory'
import { each, find, findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    completed: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    drafts: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    filter: {
      search: null,
      completed: null,
      category: null,
    },
    selectedData: {},
    extras: {
      categories: [
        { id: 1, name: 'Breakfast' },
        { id: 2, name: 'Lunch + Dinner' },
        { id: 3, name: 'Snack' },
        { id: 4, name: 'Dessert' },
        { id: 5, name: 'Sides + Sauces' },
        { id: 6, name: 'Vegan/Vegetarian Breakfast' },
        { id: 7, name: 'Vegan/Vegetarian Lunch + Dinner' },

        // Additional Recipes
        { id: 12, name: 'Christmas' },
        { id: -1, name: 'No Category' },
      ],
    },
  },

  mutations: {
    setDataList(state, data) {
      const content = state.filter.completed ? state.completed : state.drafts

      each(data, (row) => {
        const exist = find(content.list, { id: row.id })
        if (!exist) {
          content.list.push(new Food(row))
        }
      })
    },

    clearList(state) {
      let content = state.filter.completed ? state.completed : state.drafts

      content.list = []
    },

    setDataListMeta(state, meta) {
      let content = state.filter.completed ? state.completed : state.drafts

      content.listMeta = meta
    },

    setSelectedData(state, data) {
      state.selectedData = new Food(data)
    },

    setSelectedIngredientData(state, data) {
      state.selectedData.ingredients = data
    },

    setSelectedImage(state, data) {
      if (data instanceof Image) {
        state.selectedData.image = data
      } else {
        state.selectedData.image = new Image(data)
      }
    },

    setFilter(state, filter) {
      state.filter = filter
    },

    removeRecipeOnList(state, id) {
      let recipes = state.filter.completed
        ? state.completed.list
        : state.drafts.list

      let _recipeIndex = findIndex(recipes, { id })

      recipes.splice(_recipeIndex, 1)
    },
  },

  actions: {
    async getDataList({ commit }, params) {
      let { page, search, type, completed, category } = params

      const query = Food.include(['image'])
        .page(page)
        .where('type', type)

      if (completed !== undefined && type === 1) {
        query.where('completed', completed)
      }

      if (search) {
        query.where('searchable', search)
      }

      if (category) {
        query.where('category', category)
      }

      if (params.status) {
        query.where('status', params.status)
      }

      query.where('taken', 0) // exclude recipe assigned to users

      const { data, meta } = await query.params({ limit: 20 }).get()

      commit('setDataList', data)
      commit('setDataListMeta', meta)
    },

    async getSingleData({ commit }, id) {
      let content = await Food.include(['raw', 'image']).find(id)

      commit('setSelectedData', content.data)

      return content
    },

    async saveData({ commit }, params) {
      if (params.is_raw === false || params.raw.food_id === undefined) {
        delete params.raw
      }

      let food = await new Food(params).save()

      commit('setSelectedData', food)

      return food
    },

    async getIngredientData({ commit }, params) {
      const { data } = await this.$api.get(`/foods/${params.id}/ingredients`)

      commit('setSelectedIngredientData', data.data)

      return data.data
    },

    async createIngredientData({ commit }, params) {
      let { data } = await this.$api.post(
        `/foods/${params.foodId}/ingredients`,
        params
      )
      return data
    },

    async updateIngredientData({ commit }, params) {
      let { data } = await this.$api.put(
        `/foods/${params.foodId}/ingredients/${params.food_ingredient.id}`,
        params
      )
      return data
    },

    async deleteIngredientData({ commit }, params) {
      await this.$api.delete(
        `/foods/${params.foodId}/ingredients/${params.food_ingredient.id}`
      )
    },

    async changeImage({ commit }, formData) {
      const { data } = await this.$api.post(
        `foods/${formData.get('id')}/image`,
        formData
      )
      commit('setSelectedImage', data.data)
    },

    deleteRecipe({ commit }, id) {
      return new Food({ id }).delete()
    },

    async bulkImport({ commit }, formData) {
      await this.$api.post(`foods/import-recipes`, formData)
    },

    async getRecipeCategories(state) {
      const query = FoodCategory
      const { data } = await query.where('is_booster', false).get()

      data.push({ id: -1, name: 'No Category' })

      return data
    },
  },
}

export default [
  /**
   * Video Tutorials
   */
  {
    path: 'videos',
    name: 'videos',
    component: () =>
      import(/* webpackChunkName: "video" */ '@/views/Videos/Lists'),
  },

  {
    path: 'videos/new',
    name: 'new.video',
    redirect: { name: 'new.video.details' },
    component: () =>
      import(/* webpackChunkName: "new-video" */ '@/views/Videos/Informations'),
    children: [
      {
        path: ':id?/details',
        name: 'new.video.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-video-page" */ '@/views/Videos/Informations/Details'
          ),
      },
    ],
  },

  {
    path: 'videos/:id?',
    name: 'video',
    props: true,
    redirect: { name: 'video.details' },
    component: () =>
      import(/* webpackChunkName: "video" */ '@/views/Videos/Informations'),
    children: [
      {
        path: 'details',
        name: 'video.details',
        meta: { stage: 1 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "video-details" */ '@/views/Videos/Informations/Details'
          ),
      },

      {
        path: 'programs',
        name: 'video.programs',
        meta: { stage: 1 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "video-programs" */ '@/views/Videos/Informations/Programs'
          ),
      },
      {
        path: 'categories',
        name: 'video.categories',
        meta: { stage: 2 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "video-categories" */ '@/views/Videos/Informations/Categories'
          ),
      },
    ],
  },
]

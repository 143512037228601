import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Exercise extends Model {
  resource() {
    return 'exercises'
  }

  get excerciseImage() {
    if (this.image) {
      return this.image.thumb_url
    } else {
      return require('@/assets/images/default-profile.png')
    }
  }
}

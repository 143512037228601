import User from '@/models/User'
import Avatar from '@/models/Avatar'
import { each, find } from 'lodash'

const listLinks = {
  first: null,
  last: null,
  prev: null,
  next: null,
}

const filter = {
  search: null,
  type: 'all',
  joinDate: null,
  programId: null,
  startsAt: null,
  endsAt: null,
  purchaseType: null,
  socialMediaConsent: null,
  hasSelfie: null,
  membership: null,
}

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    listLinks,
    selectedUser: {
      id: null,
    },
    allUserPrograms: [],
    selectedUserProgramMealGuides: null,
    selectedUserCheckinList: null,
    selectedUserCheckin: null,
    selectedUserMealCalendar: null,
    selectedUserProgram: {
      id: null,
    },
    selectedUserProgramMeals: [],
    filter,
    extra: {
      types: {
        targetGoals: [
          { id: 1, name: 'Losing Body Fat' },
          { id: 2, name: 'Gain Muscle' },
          { id: 3, name: 'Both' },
          { id: 4, name: 'Maintenance' },
        ],
        physicalActivities: [
          { id: 1, name: 'Active' },
          { id: 2, name: 'Moderate' },
          { id: 3, name: 'Sedentary' },
        ],
        workoutFrequencies: [
          { id: 1, name: '1-2' },
          { id: 2, name: '2-3' },
          { id: 3, name: '3-4' },
          { id: 4, name: '4-5' },
          { id: 5, name: '5+' },
        ],
        restrictions: [
          { id: 0, name: 'No Restriction' },
          { id: 1, name: 'Gluten Free' },
          { id: 2, name: 'Dairy Free' },
          { id: 3, name: 'Seafood Free' },
          { id: 4, name: 'Red Meat Free' },
          { id: 5, name: 'Nut Free' },
          { id: 6, name: 'Pescetarian' },
          { id: 7, name: 'Vegetarian' },
          { id: 8, name: 'Vegan' },
        ],
        hunger: [
          { id: 1, name: 'Not Enough' },
          { id: 2, name: 'Just Right' },
          { id: 3, name: 'Too much' },
        ],
        purchaseTypes: [
          { key: 'iap', name: 'In-app Purchase' },
          { key: 'shopify', name: 'Shopify Checkouts' },
          { key: 'trial', name: 'Trial' },
          { key: 'manual', name: 'Manual' },
        ],
      },
    },

    goals: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
  },

  getters: {
    currentSelectedUserProfile(state) {
      return state.selectedUser
    },

    currentSelectedProgram(state) {
      return state.selectedUserProgram
    },

    selectedUserProgramMeals(state) {
      if (
        state.selectedUserMealCalendar &&
        state.selectedUserMealCalendar.meal_guide
      ) {
        return state.selectedUserMealCalendar.meal_guide.meals
      }
    },
  },

  mutations: {
    setFilter(state, filter) {
      state.filter = { ...state.filter, ...filter }
    },

    resetFilter(state) {
      state.filter = filter
    },

    setUserList(state, users) {
      each(users, (user) => {
        const exist = find(state.list, { id: user.id })
        if (!exist) {
          state.list.push(new User(user))
        }
      })
    },

    clearUserList(state) {
      state.list = []
      state.listLinks = listLinks
    },

    setUserListMeta(state, meta) {
      state.listMeta = meta
    },

    setUserListLinks(state, links) {
      state.listLinks = links
    },

    setselectedUser(state, user) {
      if (user instanceof User) {
        state.selectedUser = user
      } else {
        state.selectedUser = new User(user)
      }
    },

    setAllUserPrograms(state, data) {
      state.allUserPrograms = data
    },

    setUserProgram(state, data) {
      state.selectedUserProgram = data
    },

    setUserMealCalendar(state, data) {
      state.selectedUserMealCalendar = data
    },

    setUserProgramMealGuides(state, data) {
      state.selectedUserProgramMealGuides = data
    },

    setUserCheckinList(state, checkin) {
      state.selectedUserCheckinList = checkin
    },

    clearUserCheckinList(state) {
      state.selectedUserCheckinList = null
    },

    setUserCheckin(state, data) {
      state.selectedUserCheckin = data
    },

    clearUserCheckin(state) {
      state.selectedUserCheckin = null
    },

    setSelectedUserAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.selectedUser.avatar = avatar
      } else {
        state.selectedUser.avatar = new Avatar(avatar)
      }
    },

    clearselectedUser(state) {
      state.selectedUser = null
    },

    userAvatarRemoved(state) {
      state.selectedUser.avatar = null
    },

    attachUserProgram(state, userProgram) {
      state.allUserPrograms.push(userProgram)
    },
  },

  actions: {
    async getUsers({ state, commit }, params) {
      const query = User.page(params.page || 1).include(['avatar'])

      if (params.search) {
        query.where('search', params.search)
      }

      if (state.filter.type) {
        query.where('tab', state.filter.type)
      }

      if (state.filter.joinDate) {
        query.where('join_date', state.filter.joinDate)
      }

      if (state.filter.programId) {
        query.where('default_plan', state.filter.programId)
      }

      if (state.filter.startsAt) {
        query.where('default_plan_starts_date', state.filter.startsAt)
      }

      if (state.filter.endsAt) {
        query.where('default_plan_ends_date', state.filter.endsAt)
      }

      if (state.filter.purchaseType) {
        query.where('default_plan_purchase_type', state.filter.purchaseType)
      }

      if (state.filter.socialMediaConsent) {
        query.where('social_media_consent', state.filter.socialMediaConsent)
      }

      if (state.filter.hasSelfie) {
        query.where('has_selfie', state.filter.hasSelfie)
      }

      if (state.filter.membership) {
        query.where('membership', state.filter.membership)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setUserList', res.data)
      commit('setUserListMeta', res.meta)
      commit('setUserListLinks', res.links)
    },

    async getSelectedUser({ commit }, params) {
      const user = User.include(['avatar', 'profile'])

      if (params.id) {
        user.params({ currentProgram: params.id })
      }

      const { data } = await user.find(params.userId)

      commit('setselectedUser', {
        ...data,
        // profile: data.initial_profile
      })
      commit('setUserProgram', data.default_program)
    },

    assignDefaultProgram({ commit }, params) {
      return this.$api.post(`users/${params.userId}/assign-default-program`, {
        user_program_id: params.userProgramId,
      })
    },

    async getAllUserPrograms({ commit }, id) {
      let { data } = await this.$api.get(
        `/users/${id}/programs?filter[show_deactivated]=false`
      )

      commit('setAllUserPrograms', data.data)
      return data.data
    },

    async getSelectedUserProgram({ commit }, params) {
      let { data } = await this.$api.get(
        `/users/${params.userId}/programs/${params.id}`
      )

      commit('setUserProgram', data.data)
      return data.data
    },

    async getSelectedUserProgramMealGuides({ commit }, params) {
      const { data } = await this.$api.get(
        `/users/${params.userId}/programs/${params.id}/guides`
      )

      commit('setUserProgramMealGuides', data.data)
      return data.data
    },

    async getSelectedUserMealCalendar({ commit }, params) {
      const { data } = await this.$api.get(
        `/users/${params.userId}/meal-calendars?filter[date]=${params.date}`
      )

      commit('setUserMealCalendar', data.data)
    },

    async updateUserMealCalendar({ commit }, params) {
      await this.$api.post(`/users/${params.userId}/meal-calendars`, {
        meal_guide_id: params.mealGuideId,
        start_date: params.startDate,
      })
    },

    async updateUserCheckin({ commit }, formData) {
      const { data } = await this.$api.put(
        `/users/${formData.userId}/checkins/${formData.id}`,
        formData
      )
      return data
    },

    async getUserCheckinList({ commit }, params) {
      const { id, programId } = params
      const response = await this.$api.get(`users/${id}/checkin`, {
        params: { program: programId },
      })

      commit('setUserCheckinList', response.data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await this.$api.post(
        `users/${formData.get('user_id')}/avatar`,
        formData
      )
      commit('setSelectedUserAvatar', data.data)
    },

    async updateUser({ commit }, formData) {
      const { data } = await this.$api.put(`users/${formData.id}`, formData)
      return data
    },

    async updateUserProfile({ commit }, formData) {
      await this.$api.put(
        `users/${formData.user_id}/profile/${formData.id}`,
        formData
      )
    },

    async updateUserMainProfile({ commit }, formData) {
      await this.$api.put(
        `users/${formData.user_id}/main-profile/${formData.id}`,
        formData
      )
    },

    async assignSelectedProgramMealGuide({ commit }, params) {
      await this.$api.put(
        `/users/${params.userId}/programs/${params.id}/guides/assign`,
        { meal_guide_id: params.mealGuideId }
      )
    },

    async getUserPhotoGallery({ commit }, params) {
      const { data } = await this.$api.get(
        `/users/${params.userId}/programs/${params.programId}/progress-photos`
      )
      return data.data
    },

    async getUserOffboardingProgress({ commit }, params) {
      const { data } = await this.$api.get(
        `/users/${params.userId}/programs/${params.programId}/offboarding/progress`
      )

      return data.data
    },

    /**
     * Get user meal guide history
     */
    async getMealGuideHistories({ commit }, params) {
      let { page, userId, programId } = params

      return this.$api.get(
        `users/${userId}/programs/${programId}/meal-guide-history?limit=50&page=${page}`
      )
    },

    /**
     * Get user goals
     */
    async getGoalHistories({ commit }, params) {
      let { page, userId } = params

      return this.$api.get(`users/${userId}/goal-history?limit=50&page=${page}`)
    },

    /**
     * Assign new user program
     */
    assignNewUserProgram({ commit }, params) {
      let { userId, programId, variantId } = params

      return this.$api.post(
        `users/${userId}/programs/${programId}/assign-new-program`,
        {
          variant_id: variantId,
        }
      )
    },

    /**
     * Assign new user subscription
     */
    assignNewUserMembership({ commit }, params) {
      let { userId, membershipId, variantId } = params

      return this.$api.post(
        `users/${userId}/subscriptions/${membershipId}/assign-new-subscription`,
        {
          variant_id: variantId,
        }
      )
    },

    assignDefaultMembership({ commit }, params) {
      let { userId, userSubscriptionId } = params
      return this.$api.post(`users/${userId}/assign-default-subscription`, {
        user_subscription_id: userSubscriptionId,
      })
    },
  },
}

import Model from './Model'
import Avatar from './Avatar'
import Programs from './Program'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class User extends Model {
  resource() {
    return 'users'
  }

  avatar() {
    return this.hasMany(Avatar)
  }

  programs() {
    return this.hasMany(Programs)
  }

  get avatarThumbUrl() {
    if (this.avatar) {
      return this.avatar.thumb_url
    } else {
      return require('@/assets/images/default-profile.png')
    }
  }

  get avatarText() {
    return this.first_name.charAt(0) + this.last_name.charAt(0)
  }

  get joinedDate() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY')
  }

  get addedDate() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY hh:mm A')
  }
}

import Model from './Model'
import SubCategory from './SubCategory'

import { map } from 'lodash'

export default class Category extends Model {
  resource() {
    return 'categories'
  }

  children() {
    return this.hasMany(SubCategory)
  }

  get ancestorNames() {
    return map(this.ancestors, 'name')
  }

  get childrenNames() {
    return map(this.children, 'name')
  }
}

import Vue from 'vue'
import moment from 'moment-timezone'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

import * as formatters from '@/utils/formatters'
import {
  map,
  find,
  head,
  round,
  forEach,
  isArray,
  isObject,
  isNumber,
  upperFirst,
} from 'lodash'

dayjs.extend(utc)

Vue.filter('capitalize', function(value) {
  if (!value) return ''

  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('readable', function(values, $separator = ', ', property) {
  if (!values) return ''

  if (isObject(head(values))) {
    values = map(values, (value) => value[property])
  }

  return values
    .map(function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).replace(/ -/, ':')
    })
    .join($separator)
})

Vue.filter('readableEnums', function(
  values,
  enums,
  $separator = ', ',
  display = 'N/A'
) {
  if (!values) return display

  values = isArray(values) ? values : [values]
  const names = []
  forEach(values, (id) => {
    if (id === 0) return
    names.push(find(enums, { id }).name)
  })

  return names.join(($separator = ', '))
})

Vue.filter('formatedDate', function(value, format = 'lll') {
  if (value) {
    let datetime = String(value)
    if (isNumber(value)) {
      datetime = new Date(value)
    }
    return moment(datetime).format(format)
  }
})

// format must be a javascript date format
Vue.filter('formatDate', function(date, format = 'YYYY-MM-DD HH:mm') {
  if (!date) return date

  if (isObject(date)) {
    const from = dayjs.utc(date.from).format(format)
    const to = dayjs.utc(date.to).format(format)

    return `${from} - ${to}`
  }

  return dayjs.utc(date).format(format)
})

Vue.filter('formatKilogram', function(amount) {
  if (!isNumber(amount)) {
    return amount
  }

  return formatters.unit('kilogram').format(amount)
})

Vue.filter('programStatus', function(value, startsAt) {
  if (!startsAt) {
    return 'Pending'
  }

  return value
})

Vue.filter('formatCentimeter', function(amount) {
  if (!isNumber(amount)) {
    return amount
  }

  return formatters.unit('centimeter').format(amount)
})

Vue.filter('filterPrice', function(amount, currency) {
  if (!isNumber(amount)) {
    return amount
  }

  return formatters.currency(currency).format(amount)
})

Vue.filter('title', function(value) {
  return upperFirst(value) || ''
})

Vue.filter('mealGuideType', function(type) {
  let types = [
    { id: 1, type: 'User Onboarding' },
    { id: 2, type: 'User Checkin' },
    { id: 3, type: 'Update User Macro' },
    { id: 4, type: 'CMS Meal Override' },
    { id: 5, type: 'CMS Onboarding Reponse' },
    { id: 7, type: 'User Macro Reset' },
  ]

  if (!type) return

  return find(types, { id: type }).type
})

Vue.filter('deletedAccountStatus', function(status) {
  let statuses = [
    { id: 1, label: 'Pending' },
    { id: 2, label: 'Canceled' },
    { id: 3, label: 'Deleted' },
  ]

  if (!status) return

  return find(statuses, { id: status }).label
})

Vue.filter('userGoal', function(type) {
  let types = [
    { id: 1, type: 'Lose Body Fat' },
    { id: 2, type: 'Gain Muscle' },
    { id: 3, type: 'Both' },
  ]

  if (!type) return

  return find(types, { id: type }).type
})

Vue.filter('validityPlural', function(value, count) {
  return count > 1 ? `${value}s` : value
})

Vue.filter('yesNo', (value) => {
  return value === true ? 'Yes' : 'No'
})

Vue.filter('round', function(value, decimal = 2) {
  return round(value, decimal)
})

Vue.filter('pregnancyLabels', function(value) {
  if (value === 1) {
    return 'Pre-natal'
  } else if (value === 2) {
    return 'Post-natal'
  } else {
    return 'Not pregnant'
  }
})

import Challenge from '@/models/Challenge'

export const getChallenges = async ({ commit }, params) => {
  let { page, search, archived = 0, completed } = params

  const query = Challenge.page(page)
    .orderBy('name')
    .where('completed', completed)
    .where('archived', archived)
    .where('type', 2)

  if (search) {
    query.where('search', search)
  }

  const { data, meta } = await query.params({ limit: 20 }).get()

  commit('setChallengeList', data)
  commit('setChallengeListMeta', meta)
}

export const getChallenge = async ({ commit }, id) => {
  let _challenge = await Challenge.include([
    'workouts',
    'trainers',
    'videos',
  ]).find(id)

  commit('setSelectedChallenge', _challenge.data)

  return _challenge
}

export const saveChallenge = async ({ commit }, params) => {
  let _challenge = await new Challenge(params).save()
  return _challenge
}

export const archiveChallenge = async ({ commit }, params) => {
  const challenge = new Challenge({ id: params.id })
  await Challenge.custom(
    challenge,
    params.setArchive ? 'archive' : 'unarchive'
  ).get()
}

export default {
  programs: [],
  memberships: [],
  programRevenues: [],
  pauses: [],
  programRevenueTotal: {
    count: 0,
    revenue: 0,
  },
  selectedProgram: null,
  selectedMembership: null,
}

import Vue from 'vue'

const env = {
  apiUrl: process.env.VUE_APP_API_URL,
  pinnedVideosCount: process.env.VUE_APP_PINNED_VIDEO_COUNT,
}

Vue.prototype.$env = env

export default env

import Checkin from '@/models/Checkin'
import Challenge from '@/models/Challenge'
import ProgramVariant from '@/models/ProgramVariant'
import { map, each, find, extend, findIndex } from 'lodash'

export const setChallengeList = (state, challenges) => {
  const _challenge = state.filter.completed
    ? state.filter.archived
      ? state.archived
      : state.completed
    : state.drafts

  each(challenges, (challenge) => {
    const exist = find(_challenge.list, { id: challenge.id })
    if (!exist) {
      _challenge.list.push(new Challenge(challenge))
    }
  })
}

export const clearList = (state) => {
  const _challenge = state.filter.completed
    ? state.filter.archived
      ? state.archived
      : state.completed
    : state.drafts
  _challenge.list = []
}

export const setChallengeListMeta = (state, meta) => {
  const _challenge = state.filter.completed
    ? state.filter.archived
      ? state.archived
      : state.completed
    : state.drafts

  _challenge.listMeta = meta
}

export const setSelectedChallenge = (state, challenge) => {
  if (challenge instanceof Challenge) {
    state.selectedChallenge = extend(state.selectedChallenge, challenge)
  } else {
    state.selectedChallenge = new Challenge(
      extend(state.selectedChallenge, challenge)
    )
  }
}

/**
 * Challenge Checkins
 */

export const setSelectedChallengeCheckins = (state, checkins) => {
  state.selectedChallenge.checkins = map(checkins, (checkin) => {
    return new Checkin(checkin)
  })
}

export const insertSelectedChallengeCheckin = (state, checkin) => {
  state.selectedChallenge.checkins.push(new Checkin(checkin))
}

export const deleteSelectedChallengeCheckin = (state, checkin) => {
  let _index = findIndex(state.selectedChallenge.checkins, (chckn) => {
    return chckn.id === checkin.id
  })

  state.selectedChallenge.checkins.splice(_index, 1)
}

/**
 * Challenge Variants
 */

export const setSelectedChallengeVariants = (state, variants) => {
  state.selectedChallenge.variants = map(variants, (variant) => {
    return new ProgramVariant(variant)
  })
}

export const insertChallengeVariant = (state, variant) => {
  state.selectedChallenge.variants.push(new ProgramVariant(variant))
}

export const removeChallengeVariant = (state, variant) => {
  let _index = findIndex(state.selectedChallenge.variants, (vrnt) => {
    return vrnt.id === variant.id
  })

  state.selectedChallenge.variants.splice(_index, 1)
}

export const updateChallengeVariant = (state, variant) => {
  const _variant = find(state.selectedChallenge.variants, { id: variant.id })

  return Object.assign(_variant, variant)
}

/**
 * Challenge Videos
 */

export const setSelectedChallengeVideo = (state, videos) => {
  state.selectedChallenge.videos = videos
}

export const removeChallengeVideo = (state, video) => {
  let _index = findIndex(state.selectedChallenge.videos, (wrkt) => {
    return wrkt.program_video.id === video.program_video_id
  })

  state.selectedChallenge.videos.splice(_index, 1)
}

// Rich Description

export const setSelectedChallengeDescription = (state, description) => {
  state.selectedChallenge.rich_description = description
}
/**
 * Challenge Workouts
 */

export const setSelectedChallengeWorkout = (state, workouts) => {
  state.selectedChallenge.workouts = workouts
}

export const removeChallengeWorkout = (state, workout) => {
  let _index = findIndex(state.selectedChallenge.workouts, (wrkt) => {
    return wrkt.program_workout.id === workout.program_workout_id
  })

  state.selectedChallenge.workouts.splice(_index, 1)
}

export const removeChallengeDeloadWorkout = (state, workout) => {
  let _index = findIndex(state.selectedChallenge.deload_workouts, (wrkt) => {
    return wrkt.program_workout.id === workout.program_workout_id
  })

  state.selectedChallenge.deload_workouts.splice(_index, 1)
}

export const setSelectedChallengeDeloadWorkout = (state, workouts) => {
  state.selectedChallenge.deload_workouts = workouts
}

/**
 * Challenge List Management
 */

export const setFilter = (state, filter) => {
  state.filter = filter
}

/**
 * Challenge State
 */

export const clearSelectedChallenges = (state) => {
  state.selectedChallenge = {
    checkins: [],
    variants: [],
    workouts: [],
    videos: [],
    deload_workouts: [],
    completed_at: false,
    archived_at: false,
    has_workout_days: false,
    training_programs: [],
  }
}

/**
 * Toggle archive state
 */

export const shiftChallengeOnArchiveToTargetList = (
  state,
  { challenge, setArchive }
) => {
  const _targetList = setArchive ? state.archived : state.completed
  const _sourceList = !setArchive ? state.archived : state.completed

  const _challenge = find(_targetList.list, { id: challenge.id })

  if (_challenge) {
    return Object.assign(_challenge, challenge)
  }

  const _draftChallengeIndex = findIndex(_targetList.list, {
    id: challenge.id,
  })

  _targetList.list.push(challenge)
  _sourceList.list.splice(_draftChallengeIndex, 1)
}

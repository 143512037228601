<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <v-snackbar v-model="updateExists" :timeout="-1">
      New content is available on the site, you can access it by refreshing this
      page.

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" color="success" @click="refreshApp" text>
          Refresh
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Cookies from 'js-cookie'

import { mdiClose } from '@mdi/js'

export default {
  name: 'BodiesByRachelCMS',

  metaInfo() {
    return {
      meta: [
        { 'http-equiv': 'Accept-CH', content: 'DPR, Viewport-Width, Width' },
      ],
    }
  },

  data() {
    return {
      registration: null,
      updateExists: false,
      icons: {
        close: mdiClose,
      },
    }
  },

  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    refreshApp() {
      this.updateExists = false

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return

      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },

  watch: {
    $route(to, from) {
      Cookies.set('EXPIRED_TOKEN_REDIRECT_PATH', from.fullPath)
    },
  },
}
</script>

import Video from '@/models/Video'
import { each, find, isObject, findIndex } from 'lodash'

export const setVideoList = (state, videos) => {
  each(videos, (video) => {
    const exist = find(state.videos.list, { id: video.id })

    if (!exist) {
      state.videos.list.push(new Video(video))
    }
  })
}

export const setVideoListMeta = (state, meta) => {
  state.videos.listMeta = meta
}

export const setSelectedVideo = (state, video) => {
  state.selectedVideo = new Video(video)
}

export const clearList = (state) => {
  state.videos.list = []
}

export const insertVideo = (state, video) => {
  const _video = find(state.videos.list, { id: video.id })

  if (_video) {
    Object.assign(_video, video)
  } else {
    state.videos.list.push(video)
  }
}

// Programs and Challenges

export const setSelectedVideoProgram = (state, programs) => {
  state.selectedVideo.programs = programs
}

export const removeVideoProgram = (state, program) => {
  let _index = findIndex(state.selectedVideo.programs, (wrkt) => {
    return wrkt.program_video.id === program.program_video_id
  })

  state.selectedVideo.programs.splice(_index, 1)
}

export const removeVideo = (state, video) => {
  let _videoIndex = null

  if (isObject(video)) {
    _videoIndex = findIndex(state.videos.list, { id: video.id })
  } else {
    _videoIndex = findIndex(state.videos.list, { id: video })
  }

  state.videos.list.splice(_videoIndex, 1)
}

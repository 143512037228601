export default [
  {
    path: 'users',
    name: 'users',
    redirect: { name: 'users.all' },
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/Users/Lists'),
    children: [
      {
        path: 'all',
        name: 'users.all',
        meta: {
          type: 'all',
        },
        component: () =>
          import(
            /* webpackChunkName: "all-users" */ '@/views/Users/Lists/AllUsers'
          ),
      },
      {
        path: 'previous',
        name: 'users.previous',
        meta: {
          type: 'previous',
        },
        component: () =>
          import(
            /* webpackChunkName: "previous-users" */ '@/views/Users/Lists/PreviousUsers'
          ),
      },
      {
        path: 'flagged',
        name: 'users.flagged',
        meta: {
          type: 'flagged',
        },
        component: () =>
          import(
            /* webpackChunkName: "flagged-users" */ '@/views/Users/Lists/FlaggedUsers'
          ),
      },
      {
        path: 'pending',
        name: 'users.pending',
        meta: {
          type: 'pending',
        },
        component: () =>
          import(
            /* webpackChunkName: "pending-users" */ '@/views/Users/Lists/PendingUsers'
          ),
      },
      {
        path: 'social',
        name: 'users.social',
        meta: {
          type: 'social',
        },
        component: () =>
          import(
            /* webpackChunkName: "pending-users" */ '@/views/Users/Lists/SocialMediaConsent'
          ),
      },
    ],
  },
  {
    path: 'user/:id',
    name: 'user.profile',
    redirect: { name: 'user.personal-information' },
    component: () =>
      import(
        /* webpackChunkName: "user-profile" */ '@/views/Users/Profile/index'
      ),
    children: [
      {
        path: 'personal-information',
        name: 'user.personal-information',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-information" */ '@/views/Users/Profile/PersonalInformation'
          ),
      },
      {
        path: 'onboarding',
        name: 'user.onboarding',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-onboarding" */ '@/views/Users/Profile/Onboarding'
          ),
      },
      {
        path: 'meal-plan',
        name: 'user.meal-plan',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-meal-plan" */ '@/views/Users/Profile/MealPlan'
          ),
      },
      {
        path: 'meal-plan-assign',
        name: 'user.meal-plan-assign',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-meal-plan" */ '@/views/Users/Profile/MealPlanAssign'
          ),
      },
      {
        path: 'checkin',
        name: 'user.checkin',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-checkin" */ '@/views/Users/Profile/CheckIn'
          ),
      },
      {
        path: 'notifications',
        name: 'user.notifications',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-notifications" */ '@/views/Users/Profile/Notifications'
          ),
      },
      {
        path: 'gallery',
        name: 'user.gallery',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-gallery" */ '@/views/Users/Profile/Gallery'
          ),
      },
      {
        path: 'goal-history',
        name: 'user.goal-history',
        props: true,
        meta: {
          hasProgramSwitcher: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-goal-history" */ '@/views/Users/GoalHistory'
          ),
      },
      {
        path: 'meal-guide-history',
        name: 'user.meal-guide-history',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-meal-guide-history" */ '@/views/Users/MealGuideHistory'
          ),
      },
      {
        path: 'off-boarding',
        name: 'user.off-boarding',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-off-boarding" */ '@/views/Users/Offboarding'
          ),
      },
      {
        path: 'program-history',
        name: 'user.program-history',
        props: true,
        meta: {
          hasProgramSwitcher: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-programs" */ '@/views/Users/UserPrograms/List'
          ),
      },
      {
        path: 'program-revenue',
        name: 'user.program-revenue',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "user-programs-revenue" */ '@/views/Users/UserPrograms/Revenue'
          ),
      },
      {
        path: 'program-history/:programId',
        name: 'user.program-history.details',
        props: true,
        meta: {
          hasProgramSwitcher: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-program-details" */ '@/views/Users/UserPrograms/Details'
          ),
      },
      {
        path: 'membership-history',
        name: 'user.membership-history',
        props: true,
        meta: {
          hasProgramSwitcher: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-membership" */ '@/views/Users/Memberships/List'
          ),
      },
      {
        path: 'membership-history/:userSubscriptionId',
        name: 'user.membership-history.details',
        props: true,
        meta: {
          hasProgramSwitcher: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "user-membership-details" */ '@/views/Users/Memberships/Details'
          ),
      },
      {
        path: 'pause-history',
        name: 'user.pause-user',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "user-membership-pause" */ '@/views/Users/PauseMemberships/List'
          ),
      },
    ],
  },
]

import Vue from 'vue'

/**
 * Directive for handling open link in new tab on all accros list
 *
 * <td v-list-link="{ id: promo.id, routeName: 'promo.info', fullPath: `/promos/${promo.id}/details` }" >  </td>
 */
Vue.directive('listLink', {
  inserted: function(el, binding, vnode) {
    const { id, routeName, fullPath, meta } = binding.value

    el.addEventListener('click', () => {
      vnode.context.$router.push({
        name: routeName,
        params: { id: id.toString() },
        meta,
      })
    })

    const anchor = document.createElement('a')

    anchor.setAttribute('href', fullPath)
    anchor.classList.add('row-link')
    anchor.onclick = () => {
      return false
    }

    el.appendChild(anchor)
  },
})

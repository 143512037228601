export default {
  loading: false,
  deleting: false,

  promos: {
    list: [],
    list_copy: [],
    reordering: false,
    meta: {
      current_page: 0,
      from: null,
      to: null,
    },
  },

  promoTypes: {
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  selectedPromo: {
    id: null,
    title: null,
    description: null,
    featured_at: null,
    image_public_id: null,
  },
}

import Image from '@/models/Image'
import FoodSwap from '@/models/FoodSwap'
import { each, find, isNumber } from 'lodash'

export default {
  namespaced: true,
  state: {
    completed: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    drafts: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    filter: {
      search: null,
      completed: true,
    },
    selectedData: {},
  },

  mutations: {
    setDataList(state, data) {
      const content = state.filter.completed ? state.completed : state.drafts

      each(data, (row) => {
        const exist = find(content.list, { id: row.id })
        if (!exist) {
          content.list.push(new FoodSwap(row))
        }
      })
    },

    clearList(state) {
      let content = state.filter.completed ? state.completed : state.drafts

      content.list = []
    },

    setDataListMeta(state, meta) {
      let content = state.filter.completed ? state.completed : state.drafts

      content.listMeta = meta
    },

    setSelectedData(state, data) {
      if (data instanceof FoodSwap) {
        state.selectedData = data
      } else {
        state.selectedData = new FoodSwap(data)
      }
    },

    setSelectedImage(state, data) {
      if (data instanceof Image) {
        state.selectedData.image = data
      } else {
        state.selectedData.image = new Image(data)
      }
    },

    setFilter(state, filter) {
      state.filter = filter
    },
  },

  actions: {
    async getDataList({ commit }, params) {
      let { page, search } = params

      const query = FoodSwap.include(['items']).page(page)

      if (search) {
        if (isNumber(search)) {
          query.where('id', search)
        } else {
          query.where('search', search)
        }
      }

      const { data, meta } = await query.params({ limit: 10 }).get()

      commit('setDataList', data)
      commit('setDataListMeta', meta)
    },

    async getSingleData({ commit }, id) {
      let content = await FoodSwap.include(['items']).find(id)

      commit('setSelectedData', content.data)

      return content
    },

    async saveData({ commit }, params) {
      let { data } = await new FoodSwap(params).save()

      commit('setSelectedData', data)

      return data
    },

    async createSwapItem({ commit }, { params, config }) {
      const { data } = await this.$api.post(
        `/food-swaps/${params.swap_id}/items`,
        params,
        config
      )

      commit('setSelectedData', data.data)

      return data.data
    },

    async updateSwapItem({ commit }, params) {
      const { data } = await this.$api.put(
        `/food-swaps/${params.swap_id}/items/${params.id}`,
        params
      )

      commit('setSelectedData', data.data)

      return data.data
    },

    async deleteSwapItem({ commit }, params) {
      const { data } = await this.$api.delete(
        `/food-swaps/${params.swap_id}/items/${params.id}`
      )
      if (data.data.items) commit('setSelectedData', data.data)

      return data.data
    },
  },
}

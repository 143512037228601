export default {
  list: [],

  listMeta: {
    current_page: 0,
    last_page: 1,
  },

  filter: {
    starts_between: [],
    ends_between: [],
    source: null,
    user_country: null,
    programs: [],
  },

  extra: {
    sourceTypes: [
      { id: 1, name: 'Trial' },
      { id: 2, name: 'Shopify' },
      { id: 3, name: 'IAP - Android' },
      { id: 4, name: 'IAP - iOS' },
    ],
  },
}

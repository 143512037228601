export default [
  {
    path: 'plans',
    name: 'plans',
    redirect: { name: 'active.plans' },
    component: () =>
      import(/* webpackChunkName: "plans" */ '@/views/Plans/Lists'),
    children: [
      {
        path: 'active',
        name: 'active.plans',
        meta: { type: 'active' },
        component: () =>
          import(
            /* webpackChunkName: "active-plans" */ '@/views/Plans/Lists/ActivePlans'
          ),
      },
      {
        path: 'drafts',
        name: 'drafts.plans',
        meta: { type: 'drafts' },
        component: () =>
          import(
            /* webpackChunkName: "draft-plans" */ '@/views/Plans/Lists/DraftPlans'
          ),
      },
      {
        path: 'archived',
        name: 'archived.plans',
        meta: { type: 'archived' },
        component: () =>
          import(
            /* webpackChunkName: "archived-plans" */ '@/views/Plans/Lists/ArchivedPlans'
          ),
      },
    ],
  },

  {
    path: 'plans/new',
    name: 'new.plan',
    props: true,
    redirect: { name: 'new.plan.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-plan-page" */ '@/views/Plans/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.plan.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-plan-details" */ '@/views/Plans/Informations/PlanDetails'
          ),
      },
      {
        path: ':id?/programs',
        name: 'new.plan.programs',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-plan-details" */ '@/views/Plans/Informations/PlanPrograms'
          ),
      },
    ],
  },

  {
    path: 'plans/:id',
    name: 'plan.informations',
    props: true,
    redirect: { name: 'plan.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-plan-page" */ '@/views/Plans/Informations'
      ),
    children: [
      {
        path: 'details',
        name: 'plan.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-plan-details" */ '@/views/Plans/Informations/PlanDetails'
          ),
      },
      {
        path: 'programs',
        name: 'plan.programs',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-plan-details" */ '@/views/Plans/Informations/PlanPrograms'
          ),
      },
    ],
  },
]

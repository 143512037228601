import Food from '@/models/Food'
import Guide from '@/models/Guide'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    completed: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    drafts: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    deleted: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    filter: {
      search: null,
      deleted: null,
      completed: null,
      bodyType: null,
      templateName: null,
    },
    selectedData: {},
    extra: {
      mealTypes: [
        { id: 1, name: 'Upon Rising' },
        { id: 2, name: 'Pre-Workout' },
        { id: 3, name: 'Training' },
        { id: 4, name: 'Meal' },
        { id: 5, name: 'Post-Workout' },
        { id: 6, name: 'Pre-Bed' },
      ],
      bodyTypes: [
        { id: 1, name: 'ECTOMORPH' },
        { id: 2, name: 'MESOMORPH' },
        { id: 3, name: 'ENDOMORPH' },
        { id: 4, name: 'VEGAN' },
      ],

      morning: 1,
      afternoon: 2,
    },
  },

  mutations: {
    setDataList(state, data) {
      let content = state.filter.completed ? state.completed : state.drafts

      if (state.filter.deleted) {
        content = state.deleted
      }

      each(data, (row) => {
        const exist = find(content.list, { id: row.id })
        if (!exist) {
          content.list.push(new Guide(row))
        }
      })
    },

    clearList(state) {
      let content = state.filter.completed ? state.completed : state.drafts

      if (state.filter.deleted) {
        content = state.deleted
      }

      content.list = []
    },

    setDataListMeta(state, meta) {
      let content = state.filter.completed ? state.completed : state.drafts

      if (state.filter.deleted) {
        content = state.deleted
      }

      content.listMeta = meta
    },

    setSelectedData(state, data) {
      if (data instanceof Guide) {
        state.selectedData = data
      } else {
        state.selectedData = new Guide(data)
      }
    },

    setSelectedMealData(state, data) {
      state.selectedData = data
    },

    setFilter(state, filter) {
      state.filter = filter
    },
  },

  actions: {
    async getDataList({ commit }, params) {
      let { page, search, bodyType } = params

      const query = Guide.include(['mealsCount']).page(page)

      if (search) {
        query.where('search', search)
      }

      if (bodyType) {
        query.where('body_type', bodyType)
      }

      if (params.status) {
        query.where('status', params.status)
      }

      const { data, meta } = await query.params({ limit: 20 }).get()

      commit('setDataList', data)
      commit('setDataListMeta', meta)
    },

    async getGuideList({ commit }, params) {
      let {
        page,
        bodyType,
        restrictionCode,
        templateNumber,
        calories,
        status,
      } = params

      const query = Guide.include(['mealsCount']).page(page)

      if (bodyType) {
        query.where('body_type', bodyType)
      }

      if (restrictionCode) {
        query.where('restriction_code', restrictionCode)
      }

      if (templateNumber) {
        query.where('template_number', templateNumber)
      }

      if (calories) {
        query.where('calories', calories)
      }

      if (status) {
        query.where('status', status)
      }

      const { data, meta } = await query.params({ limit: 20 }).get()

      commit('setDataList', data)
      commit('setDataListMeta', meta)
    },

    async getSingleData({ commit }, params) {
      const { id } = params

      if (id === undefined) return

      let { data } = await Guide.include(['meals']).find(id)

      commit('setSelectedData', data)

      return data
    },

    async saveData({ commit }, params) {
      let { data } = await new Guide(params).save()

      commit('setSelectedData', data)

      return data
    },

    async getMeals({ commit }, params) {
      const { id } = params

      const { data } = await this.$api.get(
        `/meals/guides/${id}?include=meals.food`
      )

      commit('setSelectedMealData', data.data)

      return data.data
    },

    async createMeal({ commit }, params) {
      let { data } = await this.$api.post(
        `/meals/guides/${params.meal_guide_id}/meals`,
        params
      )
      return data
    },

    async updateMeal({ commit }, params) {
      let { data } = await this.$api.put(
        `/meals/guides/${params.meal_guide_id}/meals/${params.id}`,
        params
      )
      return data
    },

    async deleteMeal({ commit }, params) {
      await this.$api.delete(
        `/meals/guides/${params.meal_guide_id}/meals/${params.id}`
      )
    },

    async createIngredient({ commit }, params) {
      const { data } = await this.$api.post(
        `/foods/${params.foodId}/ingredients`,
        params
      )

      return data
    },

    async updateIngredient({ commit }, params) {
      const { data } = await this.$api.put(
        `/foods/${params.foodId}/ingredients/${params.id}`,
        params
      )

      return data
    },

    async deleteIngredient({ commit }, params) {
      await this.$api.delete(`/foods/${params.foodId}/ingredients/${params.id}`)
    },

    async updateFood({ commit }, params) {
      return new Food(params).save()
    },
  },
}

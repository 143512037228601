export function setPrograms(state, programs) {
  state.programs = programs
}
export function setProgramRevenues(state, data) {
  state.programRevenues = data
}

export function setProgramRevenueTotal(state, data) {
  state.programRevenueTotal = data
}

export function clearPrograms(state) {
  state.programs = []
}

export function setSelectedProgram(state, program) {
  state.selectedProgram = program
}

export function clearSelectedProgram(state, program) {
  state.selectedProgram = null
}

export function setPauses(state, pauses) {
  state.pauses = pauses
}
export function clearPauses(state) {
  state.pauses = []
}

export function attachNewProgram(state, program) {
  state.programs.push(program)
}

export function setMemberships(state, memberships) {
  state.memberships = memberships
}

export function clearMemberships(state) {
  state.memberships = []
}

export function setSelectedMembership(state, membership) {
  state.selectedMembership = membership
}

export function clearSelectedMembership(state, membership) {
  state.selectedMembership = null
}

export function attachNewMembership(state, membership) {
  state.memberships.push(membership)
}

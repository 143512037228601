import { isEmpty } from 'lodash'

export const isLoading = (state) => {
  return state.loading
}

export const hasTrainersList = (state) => {
  return !isEmpty(state.trainers.list)
}

export const trainers = (state) => {
  return state.trainers
}

export const selectedTrainer = (state) => {
  return state.selectedTrainer
}

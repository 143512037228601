import Program from '@/models/Program'
import Checkin from '@/models/Checkin'
import ProgramVariant from '@/models/ProgramVariant'
import { map, each, find, extend, findIndex } from 'lodash'

export const setProgramList = (state, programs) => {
  const _program = state.filter.completed
    ? state.filter.archived
      ? state.archived
      : state.completed
    : state.drafts

  each(programs, (program) => {
    const exist = find(_program.list, { id: program.id })
    if (!exist) {
      _program.list.push(new Program(program))
    }
  })
}

export const clearList = (state) => {
  let _program = state.filter.completed
    ? state.filter.archived
      ? state.archived
      : state.completed
    : state.drafts

  _program.list = []
}

export const setProgramListMeta = (state, meta) => {
  let _program = state.filter.completed
    ? state.filter.archived
      ? state.archived
      : state.completed
    : state.drafts

  _program.listMeta = meta
}

export const setSelectedProgram = (state, program) => {
  if (program instanceof Program) {
    state.selectedProgram = extend(state.selectedProgram, program)
  } else {
    state.selectedProgram = new Program(extend(state.selectedProgram, program))
  }
}

/**
 * Program Checkins
 */

export const setSelectedProgramCheckins = (state, checkins) => {
  state.selectedProgram.checkins = map(checkins, (checkin) => {
    return new Checkin(checkin)
  })
}

export const insertSelectedProgramCheckin = (state, checkin) => {
  state.selectedProgram.checkins.push(new Checkin(checkin))
}

export const deleteSelectedProgramCheckin = (state, checkin) => {
  let _index = findIndex(state.selectedProgram.checkins, (chckn) => {
    return chckn.id === checkin.id
  })

  state.selectedProgram.checkins.splice(_index, 1)
}

/**
 * Program Variants
 */

export const setSelectedProgramVariants = (state, variants) => {
  state.selectedProgram.variants = map(variants, (variant) => {
    return new ProgramVariant(variant)
  })
}

export const insertProgramVariant = (state, variant) => {
  state.selectedProgram.variants.push(new ProgramVariant(variant))
}

export const updateProgramVariant = (state, variant) => {
  const _variant = find(state.selectedProgram.variants, { id: variant.id })

  return Object.assign(_variant, variant)
}

export const removeProgramVariant = (state, variant) => {
  let _index = findIndex(state.selectedProgram.variants, (vrnt) => {
    return vrnt.id === variant.id
  })

  state.selectedProgram.variants.splice(_index, 1)
}

/**
 * Program Videos
 */

export const setSelectedProgramVideo = (state, videos) => {
  state.selectedProgram.videos = videos
}

export const removeProgramVideo = (state, video) => {
  let _index = findIndex(state.selectedProgram.videos, (wrkt) => {
    return wrkt.program_video.id === video.program_video_id
  })

  state.selectedProgram.videos.splice(_index, 1)
}

// Rich Description

export const setSelectedProgramDescription = (state, description) => {
  state.selectedProgram.rich_description = description
}

/**
 * Program Workouts
 */

export const setSelectedProgramWorkout = (state, workouts) => {
  state.selectedProgram.workouts = workouts
}

export const removeProgramWorkout = (state, workout) => {
  let _index = findIndex(state.selectedProgram.workouts, (wrkt) => {
    return wrkt.program_workout.id === workout.program_workout_id
  })

  state.selectedProgram.workouts.splice(_index, 1)
}

export const removeProgramDeloadWorkout = (state, workout) => {
  let _index = findIndex(state.selectedProgram.deload_workouts, (wrkt) => {
    return wrkt.program_workout.id === workout.program_workout_id
  })

  state.selectedProgram.deload_workouts.splice(_index, 1)
}

export const setSelectedProgramDeloadWorkout = (state, workouts) => {
  state.selectedProgram.deload_workouts = workouts
}

export const setFilter = (state, filter) => {
  state.filter = filter
}

/**
 * Program List Management
 */

export const updateProgramOnCompletedList = (state, program) => {
  const _program = find(state.completed.list, { id: program.id })

  if (_program) {
    return Object.assign(_program, program)
  }

  const _draftProgramIndex = findIndex(state.drafts.list, {
    id: program.id,
  })

  state.completed.list.push(program)
  state.drafts.list.splice(_draftProgramIndex, 1)
}

export const shiftProgramOnArchiveToTargetList = (
  state,
  { program, setArchive }
) => {
  const _targetList = setArchive ? state.archived : state.completed
  const _sourceList = !setArchive ? state.archived : state.completed

  const _program = find(_targetList.list, { id: program.id })

  if (_program) {
    return Object.assign(_program, program)
  }

  const _draftProgramIndex = findIndex(_targetList.list, {
    id: program.id,
  })

  _targetList.list.push(program)
  _sourceList.list.splice(_draftProgramIndex, 1)
}

export const updateProgramOnDraftList = (state, program) => {
  const _program = find(state.drafts.list, { id: program.id })

  if (_program) {
    Object.assign(_program, program)
  } else {
    state.drafts.list.push(program)
  }
}

export const clearSelectedProgram = (state) => {
  state.selectedProgram = {
    checkins: [],
    variants: [],
    videos: [],
    workouts: [],
    deload_workouts: [],
    completed_at: false,
    archived_at: false,
    has_workout_days: false,
    training_programs: [],
  }
}

export const removeProgramFormList = (state, id) => {
  return true
}

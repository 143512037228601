import Category from '@/models/Category'
import SubCategory from '@/models/SubCategory'

export const getCategories = async ({ commit }, params) => {
  let { search, page } = params

  return Category.page(page)
    .include('children')
    .where('search', search || null)
    .params({ limit: 100 })
    .get()
}

export const getCategorySubCategory = async ({ commit }, params) => {
  let { page, slug, search, includes } = params

  let categoryModel = new Category({ slug })

  return Category.custom(categoryModel, categoryModel.slug, 'sub-categories')
    .where('search', search)
    .include(includes)
    .params({ limit: 200 })
    .page(page)
    .get()
}

export const getCategoryDescendants = async ({ commit }, params) => {
  let { page, slug, search, includes } = params

  let categoryModel = new Category({ slug })

  return Category.custom(categoryModel, categoryModel.slug, 'descendants')
    .where('search', search)
    .include(includes)
    .params({ limit: 200 })
    .page(page)
    .get()
}

export const addNewMainCategory = ({ commit }, params) => {
  return new Category(params).save()
}

export const addNewSubCategory = async ({ commit }, { parent, category }) => {
  return new Category({ id: parent.id }).children().attach(category)
}

export const updateSubCategory = async ({ commit }, { parent, category }) => {
  const updatedCategory = new SubCategory(category).for(parent)
  return updatedCategory.save()
}

export const deleteCategory = ({ commit }, category) => {
  return category.delete()
}

export function reorderCategories({ commit }, categories) {
  return this.$api.post(`categories/reorder`, { categories })
}

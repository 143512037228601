import { sortBy } from 'lodash'

export const getSelectedProgram = (state) => {
  return state.selectedProgram
}

export const getSelectedProgramWorkokout = (state) => {
  return state.selectedProgram.workouts
}

export const getSelectedProgramVideo = (state) => {
  return state.selectedProgram.videos
}

export const getSelectedProgramCheckins = (state) => {
  return state.selectedProgram
    ? sortBy(state.selectedProgram.checkins, ['week', 'day_from', 'day_to'])
    : []
}

export const getSelectedProgramVariants = (state) => {
  return state.selectedProgram.variants
}

export const isSelectedProgramComplete = (state) => {
  return !!state.selectedProgram.completed_at
}

export const getSelectedProgramDeloadWorkouts = (state) => {
  return state.selectedProgram.deload_workouts
}

export const getSelectedProgramTrainingPrograms = (state) => {
  return state.selectedProgram.training_programs
}

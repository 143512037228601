export default {
  completed: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  drafts: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  archived: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  filter: {
    search: null,
    completed: null,
  },

  selectedChallenge: {
    checkins: [],
    variants: [],
    workouts: [],
    videos: [],
    deload_workouts: [],
    completed_at: false,
    has_workout_days: false,
    training_programs: [],
  },
}

import Country from '@/models/Country'

export default {
  namespaced: true,

  state: {
    list: [],
  },

  mutations: {
    setList(state, data) {
      state.list = data
    },

    clearList(state) {
      state.list = []
    },
  },

  actions: {
    async getCountries({ commit }, params) {
      const { data } = await Country.limit(300).get()

      commit('setList', data)
    },
  },
}

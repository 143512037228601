import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import users from './modules/users'
import userProfile from './modules/userProfile'
import userProgram from './modules/userProgram'
import foods from './modules/foods'
import foodCategory from './modules/foodCategory'
import liveWorkoutCategory from './modules/liveWorkoutCategory'
import meals from './modules/meals'
import plans from './modules/plans'
import recipe from './modules/recipe'
import videos from './modules/videos'
import foodSwap from './modules/foodSwap'
import programs from './modules/programs'
import workouts from './modules/workouts'
import mindsets from './modules/mindsets'
import exercises from './modules/exercises'
import challenges from './modules/challenges'
import countries from './modules/countries'
import admin from './modules/admin'
import categories from './modules/categories'
import promos from './modules/promos'
import trainers from './modules/trainers'
import deletedAccount from './modules/deletedAccount'
import workoutExtraCategories from './modules/workoutExtraCategories'
import trainingPrograms from '@/store/modules/trainingPrograms'
import memberships from '@/store/modules/memberships'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    users,
    admin,
    userProfile,
    userProgram,
    foods,
    meals,
    plans,
    recipe,
    videos,
    foodSwap,
    foodCategory,
    liveWorkoutCategory,
    programs,
    workouts,
    mindsets,
    exercises,
    challenges,
    countries,
    categories,
    promos,
    trainers,
    deletedAccount,
    workoutExtraCategories,
    trainingPrograms,
    memberships,
  },
  state: {
    controls: {
      showDrawer: null,
    },
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
  },
})

import { isEmpty, isEqual } from 'lodash'

export const isLoading = (state) => {
  return state.loading
}

export const isDeleting = (state) => {
  return state.deleting
}

export const hasPromoList = (state) => {
  return !isEmpty(state.promos.list)
}

export const promos = (state) => {
  return state.promos
}

export const selectedPromo = (state) => {
  return state.selectedPromo
}

export const promosReordered = (state) => {
  return (
    !isEqual(state.promos.list, state.promos.list_copy) &&
    state.promos.reordering
  )
}

export const getPromoTypes = (state) => () => {
  return state.promoTypes
}

export default {
  workoutExtraCategories: {
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  workoutExtraSubCategories: {
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  selectedWorkoutExtraCategory: {
    id: null,
    name: null,
    description: null,
    order: null,
    icon: null,
  },

  selectedWorkoutExtraSubCategory: {
    id: null,
    name: null,
    category_id: null,
    description: null,
  },
}

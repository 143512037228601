/* eslint-disable camelcase */
import UserProgram from '@/models/UserProgram'

export const getUserPrograms = async ({ commit }, params) => {
  const {
    page = 1,
    starts_between,
    ends_between,
    source,
    user_country,
    programs,
  } = params

  const query = UserProgram.page(page)

  if (starts_between.length === 2) {
    query.where('starts_between', starts_between.join(','))
  }

  if (ends_between.length === 2) {
    query.where('ends_between', ends_between.join(','))
  }

  if (user_country) {
    query.where('user_country', user_country)
  }

  if (source) {
    query.where('source', source)
  }

  if (programs.length > 0) {
    query.where('programs', programs.join(','))
  }

  const { data, meta } = await query.params({ limit: 20 }).get()

  commit('setUserPrograms', data)
  commit('setUserProgramsMeta', meta)
}

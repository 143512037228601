export default [
  {
    path: 'trainers',
    name: 'trainers',
    component: () =>
      import(/* webpackChunkName: "trainers" */ '@/views/Trainers/List'),
  },

  {
    path: 'trainers/new',
    name: 'new.trainer',
    redirect: { name: 'new.trainer.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "new-video" */ '@/views/Trainers/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.trainer.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-video-page" */ '@/views/Trainers/Informations/Details'
          ),
      },
    ],
  },

  {
    path: 'trainers/:id?',
    name: 'trainer.info',
    redirect: { name: 'trainer.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "new-video" */ '@/views/Trainers/Informations'
      ),
    children: [
      {
        path: 'details',
        name: 'trainer.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-video-page" */ '@/views/Trainers/Informations/Details'
          ),
      },
    ],
  },
]

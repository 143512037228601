import Model from './Model'

import { map } from 'lodash'

export default class SubCategory extends Model {
  resource() {
    return 'sub-categories'
  }

  get ancestorNames() {
    return map(this.ancestors, 'name')
  }

  get childrenNames() {
    return map(this.children, 'name')
  }
}

import utc from 'dayjs/plugin/utc'
import { find } from 'lodash'
import dayjs from 'dayjs'

// Models
import Equipment from './Equipment'
import Circuit from './Circuit'
import Model from './Model'
dayjs.extend(utc)

export default class Workout extends Model {
  resource() {
    return 'workouts'
  }

  /**
   * Relations
   */
  equipments() {
    return this.hasMany(Equipment)
  }

  circuits() {
    return this.hasMany(Circuit)
  }

  /**
   * Accessors
   */
  get equipmentsList() {
    return this.equipments ? this.equipments.join(', ') : null
  }

  get workoutVenue() {
    return this.program_workout.venue === 1 ? 'Gym' : 'Home'
  }

  get workoutDay() {
    const days = [
      { id: 0, name: 'SUNDAY' },
      { id: 1, name: 'MONDAY' },
      { id: 2, name: 'TUESDAY' },
      { id: 3, name: 'WEDNESDAY' },
      { id: 4, name: 'THURSDAY' },
      { id: 5, name: 'FRIDAY' },
      { id: 6, name: 'SATURDAY' },
    ]

    return find(days, { id: this.program_workout.day }).name
  }

  get workoutWeek() {
    return this.program_workout ? this.program_workout.week : null
  }
}

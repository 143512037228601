export default {
  loading: false,

  trainers: {
    list: [],
    meta: {
      current_page: 0,
      from: null,
      to: null,
    },
  },

  selectedTrainer: {
    id: null,
    name: null,
    image: null,
    description: null,
  },
}

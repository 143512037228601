import utc from 'dayjs/plugin/utc'
import Model from './Model'
import dayjs from 'dayjs'

dayjs.extend(utc)

export default class Equipment extends Model {
  resource() {
    return 'equipments'
  }
}

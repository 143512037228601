export default [
  {
    path: 'challenges',
    name: 'challenges',
    redirect: { name: 'current.challenges' },
    component: () =>
      import(/* webpackChunkName: "challenges" */ '@/views/Challenges'),
    children: [
      {
        path: 'current',
        name: 'current.challenges',
        component: () =>
          import(
            /* webpackChunkName: "current-challenges" */ '@/views/Challenges/Lists/CurrentChallenges'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.challenges',
        component: () =>
          import(
            /* webpackChunkName: "draft-challenges" */ '@/views/Challenges/Lists/DraftChallenges'
          ),
      },
      {
        path: 'archived',
        name: 'archived.challenges',
        component: () =>
          import(
            /* webpackChunkName: "archived-challenges" */ '@/views/Challenges/Lists/ArchivedChallenges'
          ),
      },
    ],
  },
  {
    path: 'challenges/new',
    name: 'new.challenge',
    props: true,
    redirect: { name: 'new.challenge.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-challenge" */ '@/views/Challenges/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.challenge.details',
        props: true,
        meta: { stage: 1 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-details" */ '@/views/Challenges/Informations/ChallengeDetails'
          ),
      },
      {
        path: ':id?/workout-days',
        name: 'new.challenge.workout-days',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-details" */ '@/views/Challenges/Informations/ChallengeWorkoutDays'
          ),
      },
      {
        path: ':id?/rich-description',
        name: 'new.challenge.rich-description',
        props: true,
        meta: { stage: 3 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-rich-description" */ '@/views/Challenges/Informations/ChallengeRichDescriptions'
          ),
      },
      {
        path: ':id?/checkins',
        name: 'new.challenge.checkins',
        props: true,
        meta: { stage: 4 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-checkins" */ '@/views/Challenges/Informations/ChallengeCheckins'
          ),
      },
      {
        path: ':id?/variants',
        name: 'new.challenge.variants',
        props: true,
        meta: { stage: 5 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-variants" */ '@/views/Challenges/Informations/ChallengeVariants'
          ),
      },
      {
        path: ':id?/videos',
        name: 'new.challenge.videos',
        props: true,
        meta: { stage: 6 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-videos" */ '@/views/Challenges/Informations/ChallengeVideos'
          ),
      },
      {
        path: ':id?/workouts',
        name: 'new.challenge.workouts',
        props: true,
        meta: { stage: 7 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-workouts" */ '@/views/Challenges/Informations/ChallengeWorkouts'
          ),
      },
      {
        path: ':id?/deload-workouts',
        name: 'new.challenge.deload.workouts',
        props: true,
        meta: { stage: 8 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-deload-workouts" */ '@/views/Challenges/Informations/ChallengeDeloadWorkouts'
          ),
      },
    ],
  },
  {
    path: 'challenges/:id',
    name: 'challenge',
    redirect: { name: 'challenge.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "challenge" */ '@/views/Challenges/Informations'
      ),
    children: [
      {
        path: 'details',
        name: 'challenge.details',
        meta: { stage: 1 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "challenge-details" */ '@/views/Challenges/Informations/ChallengeDetails'
          ),
      },
      {
        path: 'workout-days',
        name: 'challenge.workout-days',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-details" */ '@/views/Challenges/Informations/ChallengeWorkoutDays'
          ),
      },
      {
        path: 'rich-description',
        name: 'challenge.rich-description',
        props: true,
        meta: { stage: 3 },
        component: () =>
          import(
            /* webpackChunkName: "challenge-rich-description" */ '@/views/Challenges/Informations/ChallengeRichDescriptions'
          ),
      },
      {
        path: 'checkins',
        name: 'challenge.checkins',
        meta: { stage: 4 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "challenge-checkins" */ '@/views/Challenges/Informations/ChallengeCheckins'
          ),
      },
      {
        path: 'variants',
        name: 'challenge.variants',
        props: true,
        meta: { stage: 5 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-variants" */ '@/views/Challenges/Informations/ChallengeVariants'
          ),
      },
      {
        path: 'videos',
        name: 'challenge.videos',
        meta: { stage: 6 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "challenge-videos" */ '@/views/Challenges/Informations/ChallengeVideos'
          ),
      },
      {
        path: 'workouts',
        name: 'challenge.workouts',
        meta: { stage: 7 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "challenge-workouts" */ '@/views/Challenges/Informations/ChallengeWorkouts'
          ),
      },
      {
        path: 'deload-workouts',
        name: 'challenge.deload.workouts',
        meta: { stage: 8 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "challenge-deload-workouts" */ '@/views/Challenges/Informations/ChallengeDeloadWorkouts'
          ),
      },
    ],
  },
]

import Model from './Model'
import WorkoutExtraCategory from '@/models/WorkoutExtraCategory'

export default class WorkoutExtraSubCategory extends Model {
  resource() {
    return 'sub-categories'
  }

  category() {
    return this.belongsTo(WorkoutExtraCategory)
  }
}

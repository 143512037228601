export default [
  {
    path: 'programs',
    name: 'programs',
    redirect: { name: 'completed.programs' },
    component: () =>
      import(/* webpackChunkName: "programs" */ '@/views/Programs'),
    children: [
      {
        path: 'completed',
        name: 'completed.programs',
        component: () =>
          import(
            /* webpackChunkName: "completed-programs" */ '@/views/Programs/Lists/CompletedPrograms'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.programs',
        component: () =>
          import(
            /* webpackChunkName: "draft-programs" */ '@/views/Programs/Lists/DraftPrograms'
          ),
      },
      {
        path: 'archived',
        name: 'archived.programs',
        component: () =>
          import(
            /* webpackChunkName: "archived-programs" */ '@/views/Programs/Lists/ArchivedPrograms'
          ),
      },
    ],
  },
  {
    path: 'programs/new',
    name: 'new.program',
    props: true,
    redirect: { name: 'new.program.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-program" */ '@/views/Programs/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.program.details',
        props: true,
        meta: { stage: 1 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-details" */ '@/views/Programs/Informations/ProgramDetails'
          ),
      },
      {
        path: ':id?/workout-days',
        name: 'new.program.workout-days',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "new-challenge-details" */ '@/views/Programs/Informations/ProgramWorkoutDays'
          ),
      },
      {
        path: ':id?/rich-description',
        name: 'new.program.rich-description',
        props: true,
        meta: { stage: 3 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-rich-description" */ '@/views/Programs/Informations/ProgramRichDescriptions'
          ),
      },
      {
        path: ':id?/checkins',
        name: 'new.program.checkins',
        props: true,
        meta: { stage: 4 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-checkins" */ '@/views/Programs/Informations/ProgramCheckins'
          ),
      },
      {
        path: ':id?/variants',
        name: 'new.program.variants',
        props: true,
        meta: { stage: 5 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-variants" */ '@/views/Programs/Informations/ProgramVariants'
          ),
      },
      {
        path: ':id?/videos',
        name: 'new.program.videos',
        props: true,
        meta: { stage: 6 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-videos" */ '@/views/Programs/Informations/ProgramVideos'
          ),
      },
      {
        path: ':id?/workouts',
        name: 'new.program.workouts',
        props: true,
        meta: { stage: 7 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-workouts" */ '@/views/Programs/Informations/ProgramWorkouts'
          ),
      },
      {
        path: ':id?/deload-workouts',
        name: 'new.program.deload.workouts',
        props: true,
        meta: { stage: 8 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-deload-workouts" */ '@/views/Programs/Informations/ProgramDeloadWorkouts'
          ),
      },
    ],
  },
  {
    path: 'programs/:id',
    name: 'program',
    redirect: { name: 'program.details' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "program" */ '@/views/Programs/Informations'),
    children: [
      {
        path: 'details',
        name: 'program.details',
        meta: { stage: 1 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "program-details" */ '@/views/Programs/Informations/ProgramDetails'
          ),
      },
      {
        path: 'workout-days',
        name: 'program.workout-days',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-workout-days" */ '@/views/Programs/Informations/ProgramWorkoutDays'
          ),
      },
      {
        path: 'rich-description',
        name: 'program.rich-description',
        props: true,
        meta: { stage: 3 },
        component: () =>
          import(
            /* webpackChunkName: "program-rich-description" */ '@/views/Programs/Informations/ProgramRichDescriptions'
          ),
      },
      {
        path: 'checkins',
        name: 'program.checkins',
        meta: { stage: 4 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "program-checkins" */ '@/views/Programs/Informations/ProgramCheckins'
          ),
      },
      {
        path: 'variants',
        name: 'program.variants',
        props: true,
        meta: { stage: 5 },
        component: () =>
          import(
            /* webpackChunkName: "new-program-variants" */ '@/views/Programs/Informations/ProgramVariants'
          ),
      },
      {
        path: 'videos',
        name: 'program.videos',
        meta: { stage: 6 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "program-videos" */ '@/views/Programs/Informations/ProgramVideos'
          ),
      },
      {
        path: 'workouts',
        name: 'program.workouts',
        meta: { stage: 7 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "program-workouts" */ '@/views/Programs/Informations/ProgramWorkouts'
          ),
      },
      {
        path: 'deload-workouts',
        name: 'program.deload.workouts',
        meta: { stage: 8 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "program-deload-workouts" */ '@/views/Programs/Informations/ProgramDeloadWorkouts'
          ),
      },
    ],
  },
]

import Category from '@/models/Category'

import { findIndex } from 'lodash'

const emptySelectedCategoryState = {
  id: null,
  name: null,
  slug: null,
  order: null,
  description: null,
  is_active: null,
  children_count: null,
  children: [],
}

const empotyCategoryState = {
  list: [],
  meta: {
    current_page: 0,
    last_page: 1,
  },
}

export const setMainCategories = (state, { data, meta }) => {
  state.mainCategories.list = data
  state.mainCategories.meta = meta
}

export const setSubMainCategories = (state, { data, meta }) => {
  state.subMainCategories.list = data
  state.subMainCategories.meta = meta
}

export const setSubCategories = (state, { data, meta }) => {
  state.subCategories.list = data
  state.subCategories.meta = meta
}

export const setSelectedParentCategory = (state, { category, type }) => {
  if (type === 'main') {
    state.selectedMainCategory = category
  } else if (type === 'sub-main') {
    state.selectedSubMainCategory = category
  } else {
    state.selectedSubCategory = category
  }
}

export const clearSelectedDefaultParentCategory = (state, type) => {
  if (type === 'main') {
    state.selectedMainCategory = emptySelectedCategoryState
  } else if (type === 'sub-main') {
    state.selectedSubMainCategory = emptySelectedCategoryState
  } else {
    state.selectedSubCategory = emptySelectedCategoryState
  }
}

export const clearCategoryInformation = (state, type) => {
  if (type === 'main') {
    state.subCategories.list = empotyCategoryState.list
    state.subCategories.meta = empotyCategoryState.meta
    state.selectedSubMainCategory = emptySelectedCategoryState
  }
}

export const attachNewMainCategoryToList = (state, category) => {
  let _category = new Category(category)

  state.mainCategories.list.push(_category)
  state.selectedMainCategory.children.push(_category)
}

export const attachNewSubMainCategoryToList = (state, category) => {
  let _category = new Category(category)

  state.subMainCategories.list.push(_category)
  state.selectedMainCategory.children.push(_category)
}

export const attachNewSubCategoryToList = (state, category) => {
  let _category = new Category(category)

  state.subCategories.list.push(_category)
  state.selectedSubMainCategory.children.push(_category)
}

export const removeCategoryFromList = (state, { category, type }) => {
  if (type === 'main') {
    let _index = findIndex(state.mainCategories.list, (ctgry) => {
      return ctgry.id === category.id
    })

    state.mainCategories.list.splice(_index, 1)

    if (category.id === state.selectedMainCategory.id) {
      state.selectedMainCategory = emptySelectedCategoryState

      state.subMainCategories.list = empotyCategoryState.list
      state.subMainCategories.meta = empotyCategoryState.meta
      state.selectedSubMainCategory = emptySelectedCategoryState

      state.subCategories.list = empotyCategoryState.list
      state.subCategories.meta = empotyCategoryState.meta
      state.selectedSubCategory = emptySelectedCategoryState
    }
  } else if (type === 'sub-main') {
    let _index = findIndex(state.subMainCategories.list, (ctgry) => {
      return ctgry.id === category.id
    })

    state.subMainCategories.list.splice(_index, 1)

    if (category.id === state.selectedSubMainCategory.id) {
      state.subCategories.list = empotyCategoryState.list
      state.subCategories.meta = empotyCategoryState.meta
      state.selectedSubCategory = emptySelectedCategoryState
      state.selectedSubMainCategory = emptySelectedCategoryState
    }
  } else {
    let _index = findIndex(state.subCategories.list, (ctgry) => {
      return ctgry.id === category.id
    })

    state.subCategories.list.splice(_index, 1)
  }
}

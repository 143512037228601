import { indexOf } from 'lodash'

export const setWhitelistedUsers = (state, users) => {
  state.whitelistedUsers = users
}

export const addUserToWhitelist = (state, user) => {
  state.whitelistedUsers.push(user)
}

export const removeUserFromWhitelist = (state, user) => {
  let _index = indexOf(state.whitelistedUsers, user)

  state.whitelistedUsers.splice(_index, 1)
}

import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import MembershipVariant from '@/models/MembershipVariant'

dayjs.extend(utc)

export default class Membership extends Model {
  resource() {
    return 'subscriptions'
  }

  variants() {
    return this.hasMany(MembershipVariant)
  }

  get activeFrom() {
    return dayjs.utc(this.active_from).format('MMM DD, YYYY')
  }

  get activeTo() {
    return dayjs.utc(this.active_to).format('MMM DD, YYYY')
  }
}

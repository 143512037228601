import { each, find } from 'lodash'

export const setTrainerList = (state, { data, meta }) => {
  state.trainers.meta = meta

  each(data, (trainer) => {
    const exist = find(state.trainers.list, { id: trainer.id })

    if (!exist) {
      state.trainers.list.push(trainer)
    }
  })
}

export const setTrainerLoading = (state, loading) => {
  state.loading = loading
}

export const setSelectedTrainer = (state, trainer) => {
  state.selectedTrainer = trainer
}

export const insertTrainer = (state, trainer) => {
  const _trainer = find(state.trainers.list, { id: trainer.id })

  if (_trainer) {
    Object.assign(_trainer, trainer)
  } else {
    state.trainers.list.unshift(trainer)
  }
}

export const clearTrainerList = (state) => {
  state.trainers.list = []
}

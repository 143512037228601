import Membership from '@/models/Membership'

export const getMemberships = async ({ commit }, params) => {
  commit('setLoading', true)

  let { page, search, appends, completed } = params
  const query = Membership.page(page)
    .where('completed', completed)
    .orderBy('name')

  if (search) {
    query.where('search', search)
  }

  if (appends) {
    query.append(appends)
  }

  const { data, meta } = await query.params({ limit: 20 }).get()

  commit('setMembershipList', data)
  commit('setMembershipListMeta', meta)
  commit('setLoading', false)
}

export const getMembership = async ({ commit }, id) => {
  commit('setLoading', true)
  let _membership = await Membership.find(id)

  commit('setSelectedMembership', _membership.data)
  commit('setLoading', false)

  return _membership
}

const mapSubscriptionAccess = (form) => {
  const subscriptionAccess = {}

  for (const access of form.membership_access) {
    subscriptionAccess[access] = form[access] || []
  }

  form.subscription_access = subscriptionAccess

  return form
}

export const storeMembership = async ({ commit }, form) => {
  try {
    return await new Membership(mapSubscriptionAccess(form)).save()
  } catch (err) {
    throw err
  }
}

export const storeMembershipVariant = async ({ state, commit }, form) => {
  try {
    const membership = new Membership({ id: state.selectedMembership.id })
    const {
      data: { data: variant },
    } = await membership.variants().attach(form)
    commit('storeVariant', variant)
    return variant
  } catch (err) {
    throw err
  }
}

export async function updateMembershipVariant({ state, commit }, form) {
  const membershipId = state.selectedMembership.id
  try {
    const {
      data: { data: variant },
    } = await this.$api.put(
      `subscriptions/${membershipId}/variants/${form.id}`,
      form
    )
    commit('updateVariant', variant)
    return variant
  } catch (err) {
    throw err
  }
}

export async function deleteMembershipVariant(
  { state, commit },
  deletedVariant
) {
  const membershipId = state.selectedMembership.id
  try {
    await this.$api.delete(
      `subscriptions/${membershipId}/variants/${deletedVariant.id}`
    )
    commit('removeVariant', deletedVariant)
  } catch (err) {
    throw err
  }
}

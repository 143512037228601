export default [
  {
    path: 'exercises',
    name: 'exercises',
    redirect: { name: 'active.exercises' },
    component: () =>
      import(/* webpackChunkName: "exercises" */ '@/views/Exercises'),
    children: [
      {
        path: 'active',
        name: 'active.exercises',
        meta: { completed: true },
        component: () =>
          import(
            /* webpackChunkName: "completed-exercises" */ '@/views/Exercises/Lists/ActiveExercises'
          ),
      },
      {
        path: 'drafts',
        name: 'draft.exercises',
        meta: { completed: false },
        component: () =>
          import(
            /* webpackChunkName: "draft-exercises" */ '@/views/Exercises/Lists/DraftExercises'
          ),
      },
      {
        path: 'import',
        name: 'import.exercises',
        component: () =>
          import(
            /* webpackChunkName: "import-exercises" */ '@/views/Exercises/Lists/ImportExercises'
          ),
      },
      {
        path: 'update',
        name: 'update.exercises',
        component: () =>
          import(
            /* webpackChunkName: "import-exercises" */ '@/views/Exercises/Lists/ImportExercisesUpdates'
          ),
      },
    ],
  },
  {
    path: 'exercises/new',
    name: 'new.exercise',
    props: true,
    redirect: { name: 'new.exercise.details' },
    component: () =>
      import(
        /* webpackChunkName: "Informations" */ '@/views/Exercises/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.exercise.details',
        props: true,
        meta: { stage: 1 },
        component: () =>
          import(
            /* webpackChunkName: "new-exercise-details" */ '@/views/Exercises/Informations/ExerciseDetails'
          ),
      },
      {
        path: ':id?/instructions',
        name: 'new.exercise.instructions',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "new-exercise-instruction" */ '@/views/Exercises/Informations/ExerciseInstructions'
          ),
      },
      {
        path: ':id?/swaps',
        name: 'new.exercise.swaps',
        props: true,
        meta: { stage: 3 },
        component: () =>
          import(
            /* webpackChunkName: "new-exercise-swaps" */ '@/views/Exercises/Informations/ExerciseSwaps'
          ),
      },
    ],
  },
  {
    path: 'exercises/:id',
    name: 'exercise',
    redirect: { name: 'exercise.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Informations" */ '@/views/Exercises/Informations'
      ),
    children: [
      {
        path: 'details',
        name: 'exercise.details',
        props: true,
        meta: { stage: 1 },
        component: () =>
          import(
            /* webpackChunkName: "exercise-details" */ '@/views/Exercises/Informations/ExerciseDetails'
          ),
      },
      {
        path: 'instructions',
        name: 'exercise.instructions',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "exercise-instruction" */ '@/views/Exercises/Informations/ExerciseInstructions'
          ),
      },
      {
        path: 'swaps',
        name: 'exercise.swaps',
        props: true,
        meta: { stage: 3 },
        component: () =>
          import(
            /* webpackChunkName: "exercise-swaps" */ '@/views/Exercises/Informations/ExerciseSwaps'
          ),
      },
    ],
  },
]

import Model from './Model'
import Ingredient from './Ingredient'

export default class Food extends Model {
  resource() {
    return 'foods'
  }

  ingredients() {
    return this.hasMany(Ingredient)
  }
}

/* Helper */
function fillObject(bucketObject, tapObject, fields) {
  fields.forEach((prop) => (bucketObject[prop] = tapObject[prop]))
}

function getTrainingProgramList(trainingProgram) {
  return trainingProgram.workout_type_id === 1
    ? 'venueTrainingPrograms'
    : 'trainingTrainingPrograms'
}

/* Mutations */
export const setVenueTrainingPrograms = (state, { venueData, venueMeta }) => {
  state.venueTrainingPrograms.list = venueData
  state.venueTrainingPrograms.meta = venueMeta
}

export const setTrainingTrainingPrograms = (
  state,
  { trainingData, trainingMeta }
) => {
  state.trainingTrainingPrograms.list = trainingData
  state.trainingTrainingPrograms.meta = trainingMeta
}

export const setSelectedTrainingProgram = (state, trainingProgram) => {
  if (state.selectedTrainingProgram) {
    state.selectedTrainingProgram.selected = false
  }
  trainingProgram.selected = true
  state.selectedTrainingProgram = trainingProgram
}

export const updateOrInsertTrainingProgramItem = (
  state,
  newTrainingProgram
) => {
  const trainingProgramList = getTrainingProgramList(newTrainingProgram)
  let targetTrainingProgram = state[trainingProgramList].list.find(
    (item) => item.id === newTrainingProgram.id
  )
  if (targetTrainingProgram) {
    fillObject(targetTrainingProgram, newTrainingProgram, [
      'name',
      'description',
      'icon_public_id',
      'workout_type_id',
    ])
  } else {
    state[trainingProgramList].list.push(newTrainingProgram)
  }
}

export const removeTrainingProgramItem = (state, trainingProgram) => {
  const trainingProgramList = getTrainingProgramList(trainingProgram)
  if (state.selectedTrainingProgram === trainingProgram) {
    state.selectedTrainingProgram = {}
  }

  state[trainingProgramList].list = state[trainingProgramList].list.filter(
    (item) => item.id !== trainingProgram.id
  )
}

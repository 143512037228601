import Model from './Model'
import WorkoutExtraSubCategory from '@/models/WorkoutExtraSubCategory'

export default class WorkoutExtraCategory extends Model {
  resource() {
    return 'settings/workout-extra-categories'
  }

  subCategories() {
    return this.hasMany(WorkoutExtraSubCategory)
  }
}

import each from 'lodash/each'
import find from 'lodash/find'

export const setUserPrograms = (state, list) => {
  each(list, (userProgram) => {
    const exist = find(state.list, { id: userProgram.id })

    if (!exist) {
      state.list.push(userProgram)
    }
  })
}

export const clearUserPrograms = (state) => {
  state.list = []
}

export const setUserProgramsMeta = (state, meta) => {
  state.listMeta = meta
}

export const clearUserProgramsMeta = (state) => {
  state.listMeta = {}
}

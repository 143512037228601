import LiveWorkoutCategory from '@/models/LiveWorkoutCategory'
import { map, isNumber } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    filter: {
      search: null,
      completed: true,
    },
    selectedData: {},
    selectedVideos: [],
  },

  mutations: {
    setDataList(state, results) {
      state.list = map(results, (row) => {
        if (row instanceof LiveWorkoutCategory) {
          return row
        } else {
          return new LiveWorkoutCategory(row)
        }
      })
    },

    clearList(state) {
      state.list = []
    },

    setVideoList(state, data) {
      state.selectedVideos = data
    },

    clearVideoList(state) {
      state.selectedVideos = []
    },

    setSelectedData(state, data) {
      if (data instanceof LiveWorkoutCategory) {
        state.selectedData = data
      } else {
        state.selectedData = new LiveWorkoutCategory(data)
      }
    },

    clearSelectedData(state) {
      state.selectedData = {}
    },

    setFilter(state, filter) {
      state.filter = filter
    },
  },

  actions: {
    async getDataList({ commit }, params) {
      const { search } = params

      const query = LiveWorkoutCategory

      // eslint-disable-next-line eqeqeq
      if (search != undefined) {
        if (isNumber(search)) {
          query.where('id', search)
        } else {
          query.where('search', search)
        }
      }

      const { data } = await query.get()

      commit('setDataList', data)
    },

    async getSingleData({ commit }, id) {
      let content = await LiveWorkoutCategory.find(id)

      commit('setSelectedData', content.data)

      return content
    },

    async getVideoData({ commit }, id) {
      let content = await LiveWorkoutCategory.custom(
        `settings/live-workout-categories/${id}/videos`
      ).get()
      commit('setVideoList', content.data)
    },
    async reorderVideos({ commit }, params) {
      const { id, videos } = params

      this.$api.post(`settings/live-workout-categories/${id}/videos/reorder`, {
        videos,
      })
      // commit('setVideoList', content.data)
    },

    async saveData({ commit }, params) {
      let { data } = await new LiveWorkoutCategory(params).save()

      commit('setSelectedData', data)

      return data
    },
  },
}

import Mindset from '@/models/Mindset'

import { each, find } from 'lodash'

/**
 * Mindset List Managerment
 */

// Set list
export const setMindsetsList = (state, mindsets) => {
  each(mindsets, (mindset) => {
    const exist = find(state.mindsets.list, { id: mindset.id })

    if (!exist) {
      state.mindsets.list.push(new Mindset(mindset))
    }
  })
}

// Set list meta
export const setMindsetsListMeta = (state, meta) => {
  state.mindsets.listMeta = meta
}

// Clear mindset list
export const clearMindsetList = (state) => {
  state.mindsets.list = []
}

/**
 * Selected Mindset Information Management
 */

// Set selected mindset
export const setSelectedMindset = (state, mindset) => {
  state.selectedMindset = new Mindset(mindset)
}

// Update or Insert mindset on list
export const updateOrInsertMindset = (state, mindset) => {
  const _mindset = find(state.mindsets.list, { id: mindset.id })

  if (_mindset) {
    Object.assign(_mindset, mindset)
  } else {
    state.mindsets.list.push(mindset)
  }
}

// Clear selected mindset
export const clearSelectedMindset = (state) => {
  state.selectedMindset = {
    id: null,
    program: {},
  }
}

import Workout from '@/models/Workout'
import Circuit from '@/models/Circuit'
import { map, each, find, filter, findIndex } from 'lodash'
import WorkoutExtraCategory from '@/models/WorkoutExtraCategory'

export default {
  namespaced: true,

  state: {
    active: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    drafts: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    filter: {
      search: null,
      type: null,
      sub_category: null,
      difficulty: null,
      trainer: [],
      active: true,
    },
    venues: [
      { id: 1, type: 'gym', name: 'Gym' },
      { id: 2, type: 'home', name: 'Home' },
    ],
    selectedWorkout: {
      is_liss: false,

      circuits: [],
      equipments: [],
      training_programs: [],
    },
    selectedWorkoutCircuits: {
      gym: [],
      home: [],
    },
    selectedWorkoutEquipments: {
      home: [],
      gym: [],
    },
    extras: {
      types: [
        {
          id: 1,
          name: 'Warm Ups',
          sub_categories: [
            { id: 1, name: 'Lower Body Warm Ups' },
            { id: 2, name: 'Upper Body Warm Ups' },
            { id: 3, name: 'Full Body Warm Ups' },
            { id: 4, name: 'Mobility Sessions' },
            { id: 5, name: 'Glute Activation Circuits' },
          ],
        },
        {
          id: 2,
          name: 'Target Workouts',
          sub_categories: [
            { id: 6, name: 'Glutes Weighted' },
            { id: 7, name: 'Quads Weighted' },
            { id: 8, name: 'Hamstrings Weighted' },
            { id: 9, name: 'Glutes Quads Weighted' },
            { id: 10, name: 'Glutes Hamstrings Weighted' },
            { id: 11, name: 'Quads Hamstrings Weighted' },
            { id: 12, name: 'Glutes Quads Hamstrings Weighted' },
            { id: 13, name: 'Core Workouts' },
            { id: 14, name: 'Shoulders Weighted' },
            { id: 15, name: 'Back Weighted' },
            { id: 16, name: 'Chest Arms Weighted' },
          ],
        },
        {
          id: 3,
          name: 'Sculpting Circuits',
          sub_categories: [
            { id: 17, name: 'Lower Body Sculpting' },
            { id: 18, name: 'Upper Body Sculpting' },
            { id: 19, name: 'Full Body Sculpting' },
            { id: 20, name: 'Core Sculpting' },
          ],
        },
        {
          id: 4,
          name: 'No Equipment Workouts',
          sub_categories: [
            { id: 21, name: 'Movement Sessions' },
            { id: 22, name: 'Lower Body No Equipment' },
            { id: 23, name: 'Upper Body No Equipment' },
            { id: 24, name: 'Full Body No Equipment' },
          ],
        },
        {
          id: 5,
          name: '30 Min Hit Workouts',
          sub_categories: [
            { id: 25, name: 'Lower Body Hiit' },
            { id: 26, name: 'Upper Body Hiit' },
            { id: 27, name: 'Full Body Hiit' },
          ],
        },
        {
          id: 6,
          name: 'Cardio',
          sub_categories: [
            { id: 28, name: 'Liss Cardio' },
            { id: 29, name: 'Hiit Cardio' },
            { id: 64, name: 'Outdoor Cardio' },
          ],
        },
        {
          id: 7,
          name: 'Finishers',
          sub_categories: [
            { id: 30, name: 'Booty Finishers' },
            { id: 31, name: 'Quad Finishers' },
            { id: 32, name: 'Hamstring Finishers' },
            { id: 33, name: 'Lower Body Finishers' },
            { id: 34, name: 'Shoulder Finishers' },
            { id: 35, name: 'Back Finishers' },
            { id: 36, name: 'Chest Arm Finishers' },
            { id: 37, name: 'Full Body Finishers' },
          ],
        },
        {
          id: 8,
          name: 'Burnout Challenges',
          sub_categories: [
            { id: 38, name: 'Booty Burnout Challenges' },
            { id: 39, name: 'Quad Burnout Challenges' },
            { id: 40, name: 'Hamstring Burnout Challenges' },
            { id: 41, name: 'Lower Body Burnout Challenges' },
            { id: 42, name: 'Shoulder Burnout Challenges' },
            { id: 43, name: 'Back Burnout Challenges' },
            { id: 44, name: 'Chest Arm Burnout Challenges' },
            { id: 45, name: 'Full Body Burnout Challenges' },
          ],
        },
        {
          id: 9,
          name: 'Cool Downs',
          sub_categories: [
            { id: 46, name: 'Lower Body Cool Downs' },
            { id: 47, name: 'Upper Body Cool Downs' },
            { id: 48, name: 'Full Body Cool Downs' },
            { id: 49, name: 'Target Stretching' },
          ],
        },
        {
          id: 10,
          name: 'Express Cardio Challenges',
          sub_categories: [{ id: 50, name: 'Cardio Challenge' }],
        },
        {
          id: 11,
          name: 'Express Full Body Burners',
          sub_categories: [
            { id: 51, name: 'Full Body Resistance' },
            { id: 52, name: 'Body Weight Only' },
          ],
        },
        {
          id: 12,
          name: 'Sculpt & Define',
          sub_categories: [
            { id: 53, name: 'Lower Body' },
            { id: 54, name: 'Core' },
            { id: 55, name: 'Upper Body' },
          ],
        },
        {
          id: 13,
          name: 'Conditioning',
          sub_categories: [
            { id: 56, name: 'Lower Body Conditioning' },
            { id: 57, name: 'Upper Body Conditioning' },
            { id: 62, name: 'Full Body Conditioning' },
          ],
        },
        {
          id: 14,
          name: 'Core Workouts',
          sub_categories: [{ id: 58, name: 'Core' }],
        },
        {
          id: 15,
          name: 'Workout Challenges',
          sub_categories: [
            { id: 59, name: 'For Time' },
            { id: 60, name: 'EMOM' },
            { id: 61, name: 'Rep Challenge' },
            { id: 63, name: 'Ladder Challenge' },
          ],
        },
        {
          id: 16,
          name: 'Rachel & Festive Workouts',
          sub_categories: [{ id: 65, name: 'Festive Workouts' }],
        },
      ],
      difficulties: [
        { id: 1, name: 'Beginner' },
        { id: 2, name: 'Intermediate' },
        { id: 3, name: 'Advanced' },
      ],
    },
  },

  getters: {
    getWorkoutExtraTypes(state) {
      return state.extras.types
    },

    getWorkoutExtraSubTypes: (state) => (typeId) => {
      const type = find(state.extras.types, { id: typeId })

      return type ? type['sub_categories'] : []
    },

    getSelectedWorkoutCircuits(state) {
      return (state.selectedWorkout.circuits = map(
        state.selectedWorkout.circuits,
        (circuit, index) => {
          circuit.order = index + 1

          return circuit
        }
      ))
    },

    getSelectedWorkoutGymCircuits(state) {
      let _circuits = state.selectedWorkout.circuits

      return filter(_circuits, (circuit) => {
        return circuit.venue === 1
      })
    },

    getSelectedWorkoutHomeCircuits(state) {
      let _circuits = state.selectedWorkout.circuits

      return filter(_circuits, (circuit) => {
        return circuit.venue === 2
      })
    },
  },

  mutations: {
    setWorkoutList(state, workouts) {
      const _workout = state.filter.active ? state.active : state.drafts

      each(workouts, (workout) => {
        const exist = find(_workout.list, { id: workout.id })

        if (!exist) {
          _workout.list.push(new Workout(workout))
        }
      })
    },

    clearList(state) {
      let _workout = state.filter.active ? state.active : state.drafts

      _workout.list = []
    },

    setWorkoutListMeta(state, meta) {
      let _workout = state.filter.active ? state.active : state.drafts

      _workout.listMeta = meta
    },

    setSelectedWorkout(state, workout) {
      workout.circuits =
        each(workout.circuits, (circuit) => {
          let _exercises = map(circuit.exercises, (exercise) => {
            let _exrcs = exercise.workout_exercise

            return {
              ...exercise,
              ..._exrcs,
              ...{
                data: exercise,
                id: exercise.id,
                exercise_id: exercise.id,
              },
            }
          })

          circuit.exercises = _exercises

          return circuit
        }) || []

      let _workout = { ...state.selectedWorkout, ...workout }

      if (workout instanceof Workout) {
        state.selectedWorkout = _workout
      } else {
        state.selectedWorkout = new Workout(_workout)
      }
    },

    removeSelectedWorkoutEquipments(state, { equipment, venue }) {
      if (venue === 1) {
        let index = findIndex(state.selectedWorkoutEquipments.gym, {
          id: equipment.id,
        })

        state.selectedWorkoutEquipments.gym.splice(index, 1)
      } else {
        let index = findIndex(state.selectedWorkoutEquipments.home, {
          id: equipment.id,
        })
        state.selectedWorkoutEquipments.home.splice(index, 1)
      }
    },

    setSelectedWorkoutCircuits(state, payload) {
      let circuitState = state.selectedWorkoutCircuits

      if (Array.isArray(payload)) {
        circuitState.gym = []
        circuitState.home = []

        each(payload, (circuit) => {
          let gymCircuitState = circuitState.gym
          let homeCircuitState = circuitState.home

          const venue = circuit.venue
          const crct = venue === 1 ? gymCircuitState : homeCircuitState

          const exist = find(crct, { id: circuit.id })

          if (!exist) {
            crct.push(new Circuit(circuit))
          }
        })

        return
      }

      let venue = payload.venue
      let circuit = new Circuit(payload)

      if (venue === 1) {
        circuitState.gym.push(circuit)
      } else {
        circuitState.home.push(circuit)
      }
    },

    setFilter(state, filter) {
      state.filter = { ...state.filter, ...filter }
    },

    resetFilter(state) {
      state.filter = {
        search: null,
        type: null,
        sub_category: null,
        difficulty: null,
        trainer: null,
        active: true,
      }
    },

    addNewCircuit(state, payload) {
      state.selectedWorkout.circuits.push(payload)
    },

    clearSelectedWorkout(state, payload) {
      state.selectedWorkout = {
        circuits: [],
        equipments: [],
      }
    },

    clearSelectedWorkoutCircuits(state) {
      state.selectedWorkout.circuits = []
    },

    removeWorkoutCircuit(state, { order }) {
      const circuitIndex = findIndex(state.selectedWorkout.circuits, {
        order: order,
      })

      state.selectedWorkout.circuits.splice(circuitIndex, 1)

      state.selectedWorkout.circuits = map(
        state.selectedWorkout.circuits,
        (circuit, index) => {
          circuit.order = index + 1

          return circuit
        }
      )
    },

    removeExerciseOnCircuit(state, { circuit, exerciseIndex }) {
      const circuitIndex = findIndex(state.selectedWorkout.circuits, {
        id: circuit.id,
      })

      let exercises = state.selectedWorkout.circuits[circuitIndex]['exercises']

      exercises.splice(exerciseIndex, 1)
    },

    convertCirctuisToBeginner(state) {
      state.selectedWorkout.circuits = map(
        state.selectedWorkout.circuits,
        (circuit) => {
          circuit.difficulty = 1

          return circuit
        }
      )
    },

    updateWorkoutList(state, { complete, workout }) {
      const _draftWorkout = find(state.drafts.list, { id: workout.id })

      if (!complete) {
        if (_draftWorkout) {
          Object.assign(_draftWorkout, workout)
        } else {
          state.drafts.list.push(workout)
        }

        return true
      } else {
        const _activeWorkout = find(state.active.list, { id: workout.id })

        if (_draftWorkout) {
          const _draftWorkoutIndex = findIndex(state.drafts.list, {
            id: workout.id,
          })

          state.drafts.list.splice(_draftWorkoutIndex, 1)
        }

        if (_activeWorkout) {
          Object.assign(_activeWorkout, workout)
        } else {
          state.active.list.push(workout)
        }
      }
    },

    assignWorkoutExtraCategoryTypes(state, types) {
      state.extras.types = types
    },
  },

  actions: {
    async getWorkoutExtraCategories({ commit }, params) {
      let workoutExtraCategories = await WorkoutExtraCategory.with(
        'subCategories'
      ).get()

      let types = []

      if (workoutExtraCategories.data) {
        types = workoutExtraCategories.data.map((cat) => {
          return {
            id: cat.id,
            name: cat.name,
            sub_categories: cat.subCategories.map((subcat) => {
              return { id: subcat.id, name: subcat.name }
            }),
          }
        })
      }

      commit('assignWorkoutExtraCategoryTypes', types)
    },

    async getWorkouts({ commit }, params) {
      // eslint-disable-next-line camelcase
      let { page, search, active, type, sub_category, trainer } = params

      const query = Workout.include(['tags', 'trainer'])
        .orderBy('title')
        .page(page)
        .where('completed', active)

      if (params.search) {
        query.where('search', search)
      }

      if (type) {
        query.where('type', type)
      }

      // eslint-disable-next-line camelcase
      if (sub_category) {
        query.where('category', sub_category)
      }

      if (trainer) {
        query.where('trainer', trainer.join(','))
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setWorkoutList', res.data)
      commit('setWorkoutListMeta', res.meta)
    },

    async getWorkout({ commit }, id) {
      let _workout = await Workout.include([
        'circuits.exercises.equipments',
        'trainer',
        'tags',
        'workoutDurations',
      ]).find(id)

      let { data } = _workout

      commit('setSelectedWorkout', data)

      return _workout
    },

    saveWorkout({ commit }, params) {
      return new Workout(params).save()
    },

    async getWorkoutCircuits({ commit }, workout) {
      let _workout = new Workout({ id: workout })

      let { data } = await _workout
        .circuits()
        .include(['exercises'])
        .get()

      commit('setSelectedWorkoutCircuits', data)
    },

    async saveWorkoutCircuit({ commit }, { workout, circuit }) {
      delete circuit.id

      let _workout = await new Workout({ id: workout.id })
      let _circuit = await new Circuit(circuit).for(_workout).save()

      return _circuit
    },

    saveWorkoutCircuitMultiple({ commit }, { workout, circuits }) {
      return this.$api.post(`/workouts/${workout.id}/circuits/multiple`, {
        circuits: map(this.workoutCircuits, (circuit) => {
          circuit.exercises = map(circuit.exercises, (exercise) => {
            if (this.isLissCardioWorkout) {
              delete exercise.rounds
              delete exercise.duration
            }

            return exercise
          })

          return circuit
        }),
      })
    },

    updateWorkoutCircuitMultiple({ commit }, { workout, circuits }) {
      return this.$api.put(`/workouts/${workout.id}/circuits/multiple`, {
        circuits: map(circuits, (circuit) => {
          circuit.exercises = map(circuit.exercises, (exercise) => {
            if (this.isLissCardioWorkout) {
              delete exercise.rounds
              delete exercise.duration
            }

            return exercise
          })

          return circuit
        }),
      })
    },

    saveWorkoutEquipmentsMultiple({ commit }, { workout, equipments }) {
      return this.$api.post(`/workouts/${workout.id}/equipments/multiple`, {
        equipments,
      })
    },

    duplicateWorkout({ commit }, workoutId) {
      let workoutModel = new Workout({ id: workoutId.toString() })

      return Workout.custom(new Workout(), workoutModel.id, 'duplicate').first()
    },

    async bulkImport({ commit }, formData) {
      let result = await this.$api.post(`workouts/import-workouts`, formData)
      return result
    },

    async bulkValidate({ commit }, formData) {
      let result = await this.$api.post(
        `workouts/validate-import-workouts`,
        formData
      )
      return result
    },
  },
}

export default {
  mindsets: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  filter: {
    search: null,
    week: null,
    programs: [],
  },

  selectedMindset: {
    id: null,
    program: {},
  },
}

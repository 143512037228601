import DeletedAccount from '@/models/DeletedAccount'
import { each, find } from 'lodash'

const listMeta = {
  current_page: 0,
  last_page: 1,
}

const listLinks = {
  first: null,
  last: null,
  prev: null,
  next: null,
}

export default {
  namespaced: true,

  state: {
    list: [],
    listMeta,
    listLinks,
  },

  mutations: {
    setList(state, users) {
      each(users, (user) => {
        const exist = find(state.list, { id: user.id })
        if (!exist) {
          state.list.push(new DeletedAccount(user))
        }
      })
    },

    setListMeta(state, meta) {
      state.listMeta = meta
    },

    setListLinks(state, links) {
      state.listLinks = links
    },

    clearList(state) {
      state.list = []
      state.listLinks = listLinks
    },

    clearListMeta(state) {
      state.listMeta = listMeta
    },
  },

  actions: {
    async getLists({ commit }, params) {
      const query = DeletedAccount.page(params.page || 1)

      if (params.email) {
        query.where('email', params.email)
      }

      if (params.reason) {
        query.where('reason', params.reason)
      }

      if (params.deleted_at) {
        query.where('deleted_at', params.deleted_at)
      }

      if (params.status) {
        query.where('status', params.status)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setList', res.data)
      commit('setListMeta', res.meta)
      commit('setListLinks', res.links)
    },
  },
}

import Model from './Model'
import { find } from 'lodash'

const days = [
  { id: 0, name: 'sunday' },
  { id: 1, name: 'monday' },
  { id: 2, name: 'tuesday' },
  { id: 3, name: 'wednesday' },
  { id: 4, name: 'thursday' },
  { id: 5, name: 'friday' },
  { id: 6, name: 'saturday' },
]
export default class Mindset extends Model {
  resource() {
    return 'mindsets'
  }

  get mindsetDay() {
    return find(days, { id: this.day }).name
  }
}

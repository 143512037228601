export default [
  {
    path: 'memberships',
    name: 'memberships',
    component: () =>
      import(/* webpackChunkName: "memberships" */ '@/views/Memberships/Index'),
  },
  {
    path: 'memberships/create',
    name: 'memberships.create',
    props: true,
    redirect: { name: 'memberships.create.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-memberships" */ '@/views/Memberships/Informations/Create'
      ),
    children: [
      {
        path: 'details',
        name: 'memberships.create.details',
        props: true,
        meta: { stage: 1 },
        component: () =>
          import(
            /* webpackChunkName: "new-memberhsip-details" */ '@/views/Memberships/Informations/Details'
          ),
      },
      {
        path: 'variants',
        name: 'memberships.create.variants',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "new-membership-variants" */ '@/views/Memberships/Informations/Variants'
          ),
      },
    ],
  },
  {
    path: 'memberships/:id',
    redirect: { name: 'memberships.details' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "memberships" */ '@/views/Memberships/Informations/Create'
      ),
    children: [
      {
        path: 'details',
        name: 'memberships.details',
        props: true,
        meta: { stage: 1 },
        component: () =>
          import(
            /* webpackChunkName: "membership-details" */ '@/views/Memberships/Informations/Details'
          ),
      },
      {
        path: 'variants',
        name: 'memberships.variants',
        props: true,
        meta: { stage: 2 },
        component: () =>
          import(
            /* webpackChunkName: "membership-variants" */ '@/views/Memberships/Informations/Variants'
          ),
      },
    ],
  },
]

export default {
  // main cagtegories
  mainCategories: {
    type: 'main',
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  // Sub main cagtegories
  subMainCategories: {
    type: 'sub-main',
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  // Sub cagtegories
  subCategories: {
    type: 'sub',
    list: [],
    meta: {
      current_page: 0,
      last_page: 1,
    },
  },

  // Selected Main Category
  selectedMainCategory: {
    id: null,
    name: null,
    slug: null,
    order: null,
    is_active: null,
    description: null,
    children: [],
  },

  // Selected Sub Main Category
  selectedSubMainCategory: {
    id: null,
    name: null,
    slug: null,
    order: null,
    description: null,
    is_active: null,
    children_count: null,
    children: [],
  },

  // Selected Sub Category
  selectedSubCategory: {
    id: null,
    name: null,
    slug: null,
    order: null,
    description: null,
    is_active: null,
    children_count: null,
    children: [],
  },
}

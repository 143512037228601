export default [
  {
    path: 'food-swap',
    name: 'food-swap',
    redirect: { name: 'active.food-swap' },
    component: () =>
      import(/* webpackChunkName: "FoodSwap" */ '@/views/FoodSwap'),

    children: [
      {
        path: 'active',
        name: 'active.food-swap',
        meta: { type: 2 },
        component: () =>
          import(
            /* webpackChunkName: "active-food-swap" */ '@/views/FoodSwap/Lists/Active'
          ),
      },
    ],
  },
  {
    path: 'food-swap/:id?/form',
    name: 'form.food-swap',
    meta: { type: 2 },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "form-food-details" */ '@/views/FoodSwap/FoodSwapDetails'
      ),
  },
]

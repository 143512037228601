import Video from '@/models/Video'

export const getVideos = async ({ commit }, params) => {
  let { page, search } = params

  const query = Video.include(['tags'])
    .page(page)
    .orderBy('title')

  if (search) {
    query.where('search', search)
  }

  let { data, meta } = await query.params({ limit: 20 }).get()

  commit('setVideoList', data)
  commit('setVideoListMeta', meta)
}

export const saveVideo = ({ commit }, params) => {
  return new Video(params).save()
}

export function attachVideoCategory({ commit }, { video, form }) {
  return this.$api.post(`/videos/${video.id}/categories`, form)
}

export async function updateVideoCategory({ commit }, { video, form }) {
  return this.$api.put(`/videos/${video.id}/categories`, form)
}

export function removeVideoCategory({ commit }, { video, category }) {
  return this.$api.delete(`/videos/${video.id}/categories/${category.id}`)
}

export function deleteVideoProgram({ commit }, { program, video }) {
  return this.$api.delete(`/programs/${program}/videos/${video}`)
}

export default [
  {
    path: 'settings',
    name: 'settings',
    redirect: { name: 'settings.food-categories' },
    component: () =>
      import(/* webpackChunkName: "settings" */ '@/views/Settings'),

    children: [
      {
        path: 'food-categories',
        name: 'settings.food-categories',
        component: () =>
          import(
            /* webpackChunkName: "settings.food-categories" */ '@/views/Settings/FoodCategory'
          ),
      },
      {
        path: 'training-programs',
        name: 'settings.training-programs',
        component: () =>
          import(
            /* webpackChunkName: "settings.food-categories" */ '@/views/Settings/TrainingProgram'
          ),
      },
      {
        path: 'workout-extra-categories',
        name: 'settings.workout-extra-categories',
        component: () =>
          import(
            /* webpackChunkName: "workout-extra-categories" */ '@/views/Settings/WorkoutExtraCategory'
          ),
      },
      {
        path: 'live-workout-categories',
        name: 'settings.live-workout-categories',
        component: () =>
          import(
            /* webpackChunkName: "live-workout-categories" */ '@/views/Settings/LiveWorkoutCategory'
          ),
      },
      {
        path: 'categories',
        name: 'category-settings',
        component: () =>
          import(
            /* webpackChunkName: "category-settings" */ '@/views/Settings/Categories'
          ),
      },
    ],
  },
]

import { each, find, isObject, findIndex, clone } from 'lodash'

export const setPromoLoading = (state, loading) => {
  state.loading = loading
}

export const deletingPromo = (state, loading) => {
  state.deleting = loading
}

export const setPromoList = (state, { data, meta }) => {
  state.promos.meta = meta

  each(data, (promo) => {
    const exist = find(state.promos.list, { id: promo.id })

    if (!exist) {
      state.promos.list.push(promo)
    }
  })
}

export const setSelectedPromo = (state, promo) => {
  state.selectedPromo = promo
}

export const insertPromo = (state, promo) => {
  const _promo = find(state.promos.list, { id: promo.id })

  if (_promo) {
    Object.assign(_promo, promo)
  } else {
    state.promos.list.push(promo)
  }
}

export const removePromo = (state, promo) => {
  let _promoIndex = null

  if (isObject(promo)) {
    _promoIndex = findIndex(state.promos.list, { id: promo.id })
  } else {
    _promoIndex = findIndex(state.promos.list, { id: promo })
  }

  state.promos.list.splice(_promoIndex, 1)
}

export const clearPromoList = (state) => {
  state.promos.list = []
}

export const enablePromosReorder = (state) => {
  state.promos.list_copy = clone(state.promos.list)
  state.promos.reordering = true
}

export const cancelPromosReorder = (state, restore = true) => {
  if (restore) state.promos.list = state.promos.list_copy
  state.promos.reordering = false
}

export const setPromoTypes = (state, promoTypes) => {
  state.promoTypes.list = promoTypes
}

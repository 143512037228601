import Vue from 'vue'
import Router from 'vue-router'
import auth from './modules/auth'
import users from './modules/users'
import admin from './modules/admin'
import foods from './modules/foods'
import meals from './modules/meals'
import plans from './modules/plans'
import videos from './modules/videos'
import recipe from './modules/recipe'
import mindsets from './modules/mindsets'
import foodSwap from './modules/foodSwap'
import programs from './modules/programs'
import workouts from './modules/workouts'
import exercises from './modules/exercises'
import challenges from './modules/challenges'
import sales from './modules/sales'
import deletedAccounts from './modules/deletedAccounts'
import goTo from 'vuetify/es5/services/goto'
import settings from './modules/settings'
import promos from './modules/promos'
import trainers from './modules/trainers'
import memberships from './modules/memberships'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return goTo(0)
      }, 400)
    })
  },
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views'),
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () =>
            import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard'),
        },
        ...users,
        ...admin,
        ...foods,
        ...meals,
        ...plans,
        ...recipe,
        ...videos,
        ...mindsets,
        ...foodSwap,
        ...programs,
        ...workouts,
        ...exercises,
        ...challenges,
        ...trainers,
        ...sales,
        ...deletedAccounts,
        ...settings,
        ...promos,
        ...memberships,
      ],
    },
  ],
})

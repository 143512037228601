import isEqual from 'lodash/isEqual'

export const getCurrentPlans = (state) => {
  const _type = state.filter.type

  return state[_type]
}

export const getSelectedPlan = (state) => {
  return state.selectedPlan
}

export const getSelectedPlanPrograms = (state) => {
  return state.selectedPlan.programs
}
export const getSelectedPlanIcons = (state) => {
  return state.selectedPlan.icons
}

export const activePlansReordered = (state) => {
  return !isEqual(state.active.list, state.active.listCopy)
}

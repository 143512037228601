import Plan from '@/models/Plan'
import each from 'lodash/each'
import find from 'lodash/find'
import extend from 'lodash/extend'
import findIndex from 'lodash/findIndex'
import clone from 'lodash/clone'

// Plans list management
export const setFilter = (state, filter) => {
  state.filter = { ...state.filter, ...filter }
}

export const clearCurrentList = (state, type) => {
  state[type]['list'] = []
}

export const setCurentPlanList = (state, plans) => {
  const _type = state.filter.type
  const _plans = state[_type]

  each(plans, (plan) => {
    const planExist = find(_plans.list, { id: plan.id })

    if (!planExist) {
      _plans.list.push(new Plan(plan))
    }
  })
}

export const setCurentPlanListMeta = (state, meta) => {
  const _type = state.filter.type
  const _plans = state[_type]

  _plans.listMeta = meta
}

// Selected Plan management
export const setSelectedPlan = (state, plan) => {
  if (plan instanceof Plan) {
    state.selectedPlan = extend(state.selectedPlan, plan)
  } else {
    state.selectedPlan = extend(state.selectedPlan, new Plan(plan))
  }
}

export const resetSelectedPlan = (state) => {
  state.selectedPlan = { plans: [] }
}

export const updatePlanOnCompletedList = (state, plan) => {
  const _plan = find(state.active.list, { id: plan.id })

  if (_plan) {
    return Object.assign(_plan, plan)
  }

  const _draftPlanIndex = findIndex(state.drafts.list, {
    id: plan.id,
  })

  state.active.list.push(plan)
  state.drafts.list.splice(_draftPlanIndex, 1)
}

export const updatePlanOnDraftList = (state, plan) => {
  const _plan = find(state.drafts.list, { id: plan.id })

  if (_plan) {
    Object.assign(_plan, plan)
  } else {
    state.drafts.list.push(plan)
  }
}

export const enableActivePlansReorder = (state) => {
  state.active.listCopy = clone(state.active.list)
  state.active.reordering = true
}

export const cancelActivePlansReorder = (state, restore = true) => {
  if (restore) state.active.list = state.active.listCopy
  state.active.reordering = false
}

export const shiftPlanOnArchiveToTargetList = (state, { plan, setArchive }) => {
  const _targetList = setArchive ? state.archived : state.completed
  const _sourceList = !setArchive ? state.archived : state.completed

  const _plan = find(_targetList.list, { id: plan.id })

  if (_plan) {
    return Object.assign(_plan, plan)
  }

  const _draftPlanIndex = findIndex(_targetList.list, {
    id: plan.id,
  })

  _targetList.list.push(plan)
  _sourceList.list.splice(_draftPlanIndex, 1)
}
export const setSelectedPlanIcons = (state, icons) => {
  state.selectedPlan.icons = icons
}

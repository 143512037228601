import Model from './Model'
import Category from './Category'
import Program from './Program'

export default class Video extends Model {
  resource() {
    return 'videos'
  }

  categories() {
    return this.hasMany(Category)
  }

  programs() {
    return this.hasMany(Program)
  }
}

export default {
  list: [],
  listMeta: {
    current_page: 1,
    last_page: 1,
  },
  filter: {
    search: null,
    completed: null,
  },
  selectedMembership: {
    variants: [],
    completed_at: false,
  },
  isLoading: true,
}

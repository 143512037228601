import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// Primary Color is #9F1E52
// Created a color shades tailwind color shade generator
// https://javisperez.github.io/tailwindcolorshades/#/?Night%20Shadz=9f1e52&tv=1

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#A70057',
          lighten1: '#BC6286',
          lighten2: '#D9A5BA',
          lighten3: '#E7C7D4',
          lighten4: '#F5E9EE',
          darken1: '#8F1B4A',
          darken2: '#811843',
          darken3: '#5F1231',
          darken4: '#480E25',
          darken5: '#300919',
        },
        secondary: '#FF5D8E',
        accent: '#FF5D8E',
        error: '#c21e51',
        grey: {
          base: '#9E9E9E',
          lighten3: '#f1f3f4',
        },
      },
    },
  },
})

import Trainer from '@/models/Trainer'

/**
 * Getting list of trainers.
 *
 * @param {object} params
 *
 * declare false when you need to return the data
 * without moving it to state.
 * eg. using data on search input
 * @param {boolean} toState
 */
export const getTrainers = async (
  { commit },
  { page, search, limit = 50, toState = true }
) => {
  commit('setTrainerLoading', true)

  const query = Trainer.page(page)

  if (search) {
    query.where('search', search)
  }

  let list = await query.params({ limit }).get()

  commit('setTrainerLoading', false)

  if (toState) {
    commit('setTrainerList', list)
  } else {
    return list
  }
}

export const getTrainer = async ({ commit }, id) => {
  commit('setTrainerLoading', true)

  let trainer = await Trainer.$find(id)

  commit('setTrainerLoading', false)
  commit('setSelectedTrainer', trainer)
}

export const saveTrainer = ({ commit }, params) => {
  return new Trainer(params).save()
}

export default {
  active: {
    list: [],
    listCopy: [],
    reordering: false,
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  drafts: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },
  archived: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  filter: {
    search: null,
    type: 'active',
    completed: true,
    archived: false,
  },

  selectedPlan: {
    icons: [],
    programs: [],
  },
}

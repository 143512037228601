import Image from '@/models/Image'
import Food from '@/models/Food'
import { each, find, findIndex, isNumber } from 'lodash'

export default {
  namespaced: true,
  state: {
    completed: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    drafts: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    filter: {
      search: null,
      completed: null,
    },
    selectedData: {},
    flavour_booster: {
      categories: [
        { id: 8, name: 'Sauces' },
        { id: 10, name: 'Spices & Herbs' },
        { id: 11, name: 'Sweeteners' },
      ],
    },
  },

  mutations: {
    setDataList(state, data) {
      const content = state.filter.completed ? state.completed : state.drafts

      each(data, (row) => {
        const exist = find(content.list, { id: row.id })
        if (!exist) {
          content.list.push(new Food(row))
        }
      })
    },

    clearList(state) {
      let content = state.filter.completed ? state.completed : state.drafts

      content.list = []
    },

    setDataListMeta(state, meta) {
      let content = state.filter.completed ? state.completed : state.drafts

      content.listMeta = meta
    },

    setSelectedData(state, data) {
      if (data instanceof Food) {
        state.selectedData = data
      } else {
        state.selectedData = new Food(data)
      }
    },

    setSelectedImage(state, data) {
      if (data instanceof Image) {
        state.selectedData.image = data
      } else {
        state.selectedData.image = new Image(data)
      }
    },

    setFilter(state, filter) {
      state.filter = filter
    },

    removeFoodOnList(state, id) {
      let foods = state.filter.completed
        ? state.completed.list
        : state.drafts.list

      let _foodIndex = findIndex(foods, { id })

      foods.splice(_foodIndex, 1)
    },
  },

  actions: {
    async getDataList({ commit }, params) {
      let { page, search, type } = params

      const query = Food.include(['image'])
        .page(page)
        .where('type', type)

      if (search) {
        if (isNumber(search)) {
          query.where('id', search)
        } else {
          query.where('searchable', search)
        }
      }

      if (params.status) {
        query.where('status', params.status)
      }

      query.where('taken', 0) // exclude foods assigned to users

      const { data, meta } = await query.params({ limit: 20 }).get()

      commit('setDataList', data)
      commit('setDataListMeta', meta)
    },

    async getSingleData({ commit }, id) {
      let content = await Food.include(['raw', 'image']).find(id)

      commit('setSelectedData', content.data)

      return content
    },

    async saveData({ commit }, params) {
      if (params.is_raw === false || params.raw.food_id === undefined) {
        delete params.raw
      }
      let data = await new Food(params).save()

      commit('setSelectedData', data)

      return data
    },

    async changeImage({ commit }, formData) {
      const { data } = await this.$api.post(
        `foods/${formData.get('id')}/image`,
        formData
      )
      commit('setSelectedImage', data.data)
    },

    deleteFood({ commit }, id) {
      return new Food({ id }).delete()
    },

    async bulkImport({ commit }, formData) {
      await this.$api.post(`foods/import-foods`, formData)
    },
  },
}

export default [
  {
    path: 'mindsets',
    name: 'mindsets',
    component: () =>
      import(/* webpackChunkName: "mindset" */ '@/views/Mindset/Lists'),
  },

  {
    path: 'mindsets/new',
    name: 'new.mindset',
    redirect: { name: 'new.mindset.details' },
    component: () =>
      import(
        /* webpackChunkName: "new-mindset" */ '@/views/Mindset/Informations'
      ),
    children: [
      {
        path: ':id?/details',
        name: 'new.mindset.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-mindset-page" */ '@/views/Mindset/Informations/MindsetDetails'
          ),
      },
    ],
  },

  {
    path: 'mindsets/:id?',
    name: 'mindset',
    props: true,
    redirect: { name: 'mindset.details' },
    component: () =>
      import(/* webpackChunkName: "mindset" */ '@/views/Mindset/Informations'),
    children: [
      {
        path: 'details',
        name: 'mindset.details',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "mindset-details" */ '@/views/Mindset/Informations/MindsetDetails'
          ),
      },
    ],
  },
]
